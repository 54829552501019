import React from 'react';

export const CreditPlanColumns = [
  {
    name: 'id',
    selector: (row: any) => row.id,
    center: true,
    width: '60px',
  },
  {
    name: '이름',
    selector: (row: any) => row.title,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: '크레딧',
    cell: (row: any) => {
      return (
        <span>
          <b>{row.credit.toLocaleString()}</b>크레딧
        </span>
      );
    },
    sortable: true,
    center: true,
    wrap: true,
    width: '130px',
  },
  {
    name: '가격',
    cell: (row: any) => {
      return (
        <span>
          <b>{row.price.toLocaleString()}</b>원
        </span>
      );
    },
    sortable: true,
    center: true,
    wrap: true,
    width: '100px',
  },
  {
    name: '할인율',
    selector: (row: any) => row.discountRate * 100 + '%',
    sortable: true,
    center: true,
    wrap: true,
    width: '80px',
  },
  {
    name: '활성 여부',
    selector: (row: any) => row.active,
    cell: (row: any) => {
      return row.active ? (
        <span className="badge badge-success">활성</span>
      ) : (
        <span className="badge badge-danger">비활성</span>
      );
    },
    sortable: true,
    center: true,
    wrap: true,
    width: '100px',
  },
];
