// Login API
// src/api/authApi.ts
import APIClient from './APIClient';

export interface ReqCredentials {
  email: string;
  password?: string;
}

export interface ResJwtToken {
  grantType: string;
  accessToken?: string;
  refreshToken?: string;
}

export const postLogin = async (credentials: ReqCredentials) => {
  const response = await APIClient.post('/v2/api/account/login', credentials);
  return response.data;
};

export const getMe = async () => {
  const response = await APIClient.get(`/v2/api/me/auth`);
  return response.data;
};

/**
 * 유저 로그인
 * */
export const userLogin = async (credentials: ReqCredentials) => {
  const response = await APIClient.post<ResJwtToken>('/v2/api/account/login/user', credentials);
  return response.data;
};
