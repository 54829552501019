import React, { Fragment, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { getCategoryIndustryList } from '../../../api/industryAPI';
import useApiError from '../../../hooks/useApiError';
import Breadcrumb from '../../../components/common/breadcrumb';
import { CategoryIndustryColumns } from '../data/industry-columns';
import { cellsStyle, headCellsStyle, rowsStyle } from '../../../data/customizer/react-data-table-config';

const IndustryCategoryList = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const navigate = useNavigate();
  const [pageInfoData, setPageInfoData] = useState<any[]>([]);

  const { isLoading } = useQuery(['getCategoryIndustryPage'], () => getCategoryIndustryList(), {
    onSuccess: (data) => {
      setPageInfoData(data || []);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  //  행 클릭 핸들러
  const handleRowClicked = (row: any) => {
    navigate(`/admin/industry/category/update/${row.id}`);
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-10">
            <Breadcrumb parent="산업군 관리" title="산업군 카테고리 목록">
              <Link className="btn btn-primary" to={`/admin/industry/category/update`}>
                새로 등록
              </Link>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          {/*  :::::::::: List ::::::::::  */}
          <div className="col-xl-10 mb-4">
            <DataTable
              noHeader
              columns={CategoryIndustryColumns}
              highlightOnHover
              pointerOnHover
              data={pageInfoData}
              progressPending={isLoading}
              onRowClicked={handleRowClicked}
              dense={true}
              customStyles={{
                rows: rowsStyle,
                headCells: headCellsStyle,
                cells: cellsStyle,
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default IndustryCategoryList;
