import APIClient from './APIClient';
import { PageInfo } from './types';

export interface ReqPlan {
  id: number;
  title: string;
  description: string;
  price: number;
  priceOfMember: number;
  discountRate: number;
  level: number;
  storage: number;
  memberCount: number;
  credit: number;
  active: boolean;
}
/** 플랜 정보 */
export interface ResPlan {
  id: number;
  title: string;
  description: string;
  price: number;
  priceOfMember: number;
  discountPrice: number;
  priceOfYear: number;
  discountRate: number;
  level: number;
  storage: number;
  memberCount: number;
  credit: number;
  active: boolean;
  createdDate?: string;
  updatedDate?: string;
}
export interface ResPlanList {
  id: number;
  title: string;
  price: number;
  priceOfYear: number;
  discountRate: number;
  level: number;
  credit: number;
  active: boolean;
}
/** 플랜 목록 요청 파라미터 */
export interface ReqPlanList extends PageInfo {
  active?: boolean | undefined;
}

/** 플랜 목록 요청.*/
export const getPlanList = async (params: ReqPlanList) => {
  const response = await APIClient.get(`/v2/api/plan/list`, {
    params,
  });
  return response.data;
};

/**
 * [Plan] 페이징
 * */
export const getPlanPage = async (params: ReqPlanList) => {
  const response = await APIClient.get('/v2/api/plan/page', {
    params,
  });
  return response.data;
};

/**
 * [Plan] 단일 조회
 * */
export const getPlan = async (idPlan: number) => {
  const response = await APIClient.get(`/v2/api/plan/${idPlan}`);
  return response.data;
};

/**
 * [Plan] 생성
 * */
export const createPlan = async (plan: ReqPlan) => {
  const response = await APIClient.post('/v2/api/plan', plan);
  return response.data;
};

/**
 * [Plan] 수정
 * */
export const updatePlan = async ({ idPlan, body }: { idPlan: number; body: ReqPlan }) => {
  const response = await APIClient.patch(`/v2/api/plan/${idPlan}`, body);
  return response.data;
};

/**
 * [Plan] 삭제
 * */
export const deletePlan = async (idPlan: number) => {
  const response = await APIClient.delete(`/v2/api/plan/${idPlan}`);
  return response.data;
};
