import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import React, { useCallback, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useQuery } from 'react-query';
import useApiError from '../../../hooks/useApiError';
import { getCreditPage, ReqCreditPage } from '../../../api/creditAPI';
import { creditColumns } from '../data/credit-columns';
import { useNavigate } from 'react-router-dom';
import ManageCreditModal from './ManageCreditModal';
import { cellsStyle, headCellsStyle, rowsStyle } from '../../../data/customizer/react-data-table-config';

interface PropsType {
  idBrand?: any;
  creditBrand?: any;
  onUpdateCredit?: () => void;
}
function ManageCredit({ idBrand, creditBrand, onUpdateCredit }: PropsType) {
  const handleApiError = useApiError(); // 에러 처리 훅
  const navigate = useNavigate();
  const [dropdownOpen, setOpen] = useState(false);
  const [isCreditModal, setIsCreditModal] = useState(false);
  const [isCreditAdd, setIsCreditAdd] = useState(false);
  //
  const [listData, setListData] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [reqParams, setReqParams] = useState<ReqCreditPage>({
    page: 0,
    size: 5,
    sort: '',
  });

  /**
   * 페이지 데이터 요청.
   * */
  const { isLoading, refetch } = useQuery(
    ['getCreditPage', reqParams],
    () => getCreditPage({ ...reqParams, idBrand: idBrand }),
    {
      enabled: !!idBrand, // brand.id가 있을 때만 요청 실행
      onSuccess: (data) => {
        setListData(data?._embedded?.credits || []);
        setTotalRows(data?.page?.totalElements || 0);
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 모달이 닫힐 때 refetch 실행
   * **/
  const onSuccessPatchCredit = useCallback(() => {
    // 크레딧 목록 업데이트
    refetch();
    // 크레딧 업데이트 완료 콜백
    if (onUpdateCredit) {
      onUpdateCredit();
    }
    // 모달 닫기.
    setIsCreditModal(false);
  }, []);

  /**
   *  페이지 변경 핸들러
   * */
  const handlePageChange = (page: number) => {
    setReqParams({
      ...reqParams,
      page: page - 1,
    });
  };

  /**
   * 페이지 사이즈 변경
   * */
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParams({
      ...reqParams,
      size: newPerPage,
      page: page - 1,
    });
  };

  /**
   * 드롭다운 토클
   * */
  const dropdownToggle = () => {
    setOpen(!dropdownOpen);
  };

  /**
   * 행 클릭 핸들러
   * */
  const handleRowClicked = (row: any) => {
    navigate(`/admin/credit/update/${row.id}?idBrand=${idBrand}`);
  };

  return (
    <div>
      {/* ::::::: 적립 / 차감 모달 ::::::: */}
      <ManageCreditModal
        headerTitle={'크레딧 적립 / 차감'}
        idBrand={Number(idBrand)}
        brandCredit={creditBrand}
        onSuccess={onSuccessPatchCredit}
        isCreditAdd={isCreditAdd}
        isOpen={isCreditModal}
        toggle={() => setIsCreditModal(!isCreditModal)}
      ></ManageCreditModal>
      {/* ::::::: 적립 / 차감 모달 ::::::: */}
      <div className="card">
        <div className={'card-header'}>
          <h5>
            크레딧 관리&nbsp; (보유 :{' '}
            <b className={'text-primary'}>
              {creditBrand ? creditBrand.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}
            </b>{' '}
            <small>CREDIT</small>)
          </h5>
          {idBrand && (
            <Dropdown isOpen={dropdownOpen} toggle={dropdownToggle}>
              <DropdownToggle size={'sm'} color="dark" outline={true}>
                <span>
                  작업 <i className="fa fa-caret-down"></i>
                </span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => navigate(`/admin/credit/list?idBrand=${idBrand}`)}>
                  {'전체 내역 조회'}
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setIsCreditAdd(true);
                    setIsCreditModal(true);
                  }}
                >
                  {'크레딧 적립'}
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setIsCreditAdd(false);
                    setIsCreditModal(true);
                  }}
                >
                  {'크레딧 차감'}
                </DropdownItem>
                {/*<DropdownItem onClick={() => handleCredit('PLUS')}>{'크레딧 적립'}</DropdownItem>*/}
                {/*<DropdownItem onClick={() => handleCredit('MINUS')}>{'크레딧 차감'}</DropdownItem>*/}
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
        {!idBrand ? (
          <div className={'card-body'}>
            <p className={`text-center m-5-15 text-danger`}>브랜드를 연결하세요.</p>
          </div>
        ) : (
          <>
            <div className={'card-table-body'}>
              <DataTable
                noHeader
                columns={creditColumns}
                data={listData}
                progressPending={isLoading}
                highlightOnHover
                pointerOnHover
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationPerPage={reqParams.size}
                paginationRowsPerPageOptions={[5, 10, 15]}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onRowClicked={handleRowClicked}
                dense={true}
                customStyles={{
                  rows: rowsStyle,
                  headCells: headCellsStyle,
                  cells: cellsStyle,
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default React.memo(ManageCredit);
