import { PageInfo } from './types';
import APIClient from './APIClient';
import { AxiosResponse } from 'axios';

export type TemplateSizeUnit = 'px' | 'mm' | 'cm';

export interface ReqTemplateForm {
  name?: string;
  width?: number;
  height?: number;
  sizeUnit?: TemplateSizeUnit;
  top?: boolean;
  active?: boolean;
}

export interface ReqTemplateFormPage extends PageInfo {
  sizeUnit?: TemplateSizeUnit;
  active?: boolean;
}

export interface ResTemplateForm {
  idTemplateForm?: number;
  name?: string;
  width?: number;
  height?: number;
  sizeUnit?: TemplateSizeUnit;
  top?: boolean;
  active?: boolean;
}

export interface ResTemplateFormList {
  idTemplateForm?: number;
  name?: string;
  active?: boolean;
}

/** 페이징 */
export const getTemplateFormPage = async (params: ReqTemplateFormPage) => {
  const response = await APIClient.get('/v2/api/template-form/page', {
    params,
  });
  return response.data;
};

/** 단일 조회 */
export const getTemplateForm = async (idTemplateForm: number) => {
  const response: AxiosResponse<ResTemplateForm> = await APIClient.get(`/v2/api/template-form/${idTemplateForm}`);
  return response.data;
};

/** 생성 */
export const createTemplateForm = async (body: ReqTemplateForm) => {
  const response = await APIClient.post('/v2/api/template-form', body);
  return response.data;
};

/** 수정 */
export const updateTemplateForm = async ({
  idTemplateForm,
  body,
}: {
  idTemplateForm: number;
  body: ReqTemplateForm;
}) => {
  const response = await APIClient.patch(`/v2/api/template-form/${idTemplateForm}`, body);
  return response.data;
};

/** 삭제 */
export const deleteTemplateForm = async (idTemplateForm: number) => {
  const response = await APIClient.delete(`/v2/api/template-form/${idTemplateForm}`);
  return response.data;
};

/**
 * Enum 타입 조회.
 * */
export const getTemplateFormEnum = async () => {
  const response = await APIClient.get('/v2/api/template-form/enum');
  return response.data;
};
