import React, { ReactElement, useRef, useState } from 'react';
import { TBFormGroup, TBLabel } from '../../styledComponents';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import { X } from 'react-feather';

interface DefaultProps {
  label?: string;
  message?: string | ReactElement;
  id?: string;
  name: string;
  onChange?: (newValue: string[]) => void;
  placeholder?: string;
  disabled?: boolean;
  horizontal?: boolean;
  errors: any;
  control?: any;
  validation?: any;
}
const TbInputTag = ({
  label = '',
  message = '',
  id = '',
  name = '',
  onChange = undefined,
  horizontal = false,
  errors = {},
  control,
  validation = undefined,
}: DefaultProps) => {
  const [inputValue, setInputValue] = useState('');
  const refInput = useRef<HTMLInputElement>(null);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, onChangeField: any, fieldValue: string[]) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // 기본 폼 제출 방지
      const trimmedValue = inputValue.trim();

      if (trimmedValue) {
        // 콤마로 값을 분리한 후 트리밍
        const newValues = trimmedValue.split(',').map((value) => value.trim());

        // 기존 값과 새 값을 병합
        const mergedValues = [...(fieldValue || []), ...newValues];

        // 상태 업데이트
        onChangeField(mergedValues); // Controller의 상태 변경
        if (onChange) onChange(mergedValues); // 외부 onChange 호출

        // 입력 필드 초기화
        setInputValue('');
      }
    }
  };

  const handleDelete = (index: number, onChangeField: any, fieldValue: string[]) => {
    const newValue = [...fieldValue];
    newValue.splice(index, 1);
    onChangeField(newValue);
    if (onChange) onChange(newValue); // 외부 onChange 호출
  };

  return (
    <TBFormGroup horizontal={horizontal.toString()}>
      {label && (
        <TBLabel horizontal={horizontal.toString()}>
          {label}
          {validation?.required && <span className={'text-danger'}>(필수)</span>}
        </TBLabel>
      )}
      <div className={'w-100'}>
        <Controller
          control={control}
          name={name}
          rules={validation}
          render={({ field }) => (
            <>
              <TagWrap
                className="form-control"
                onMouseDown={(e) => {
                  refInput.current?.focus();
                  e.preventDefault();
                }}
              >
                {field.value?.map((tag: string, index: number) => (
                  <TagItem key={index} className="badge badge- badge-primary">
                    {tag}
                    <TagDelete onClick={() => handleDelete(index, field.onChange, field.value)}>
                      <X size={24} />
                    </TagDelete>
                  </TagItem>
                ))}
                <TagInput
                  ref={refInput}
                  id={id}
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                  onKeyUp={(e) => handleKeyDown(e, field.onChange, field.value)}
                  placeholder={'태그 입력 후 Enter'}
                />
              </TagWrap>
            </>
          )}
        />
        {errors[name] && (
          <div className="text-danger mt-1">
            {errors[name]?.message ? errors[name].message : `${label}을/를 입력하세요.`}
          </div>
        )}
        {message && <div className={'mt-1'}>{message}</div>}
      </div>
    </TBFormGroup>
  );
};

export default React.memo(TbInputTag);

const TagWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const TagItem = styled.span`
  position: relative;
  padding-right: 26px;
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 0 !important;
  margin-right: 4px;
`;

const TagDelete = styled.span`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 4px;
  right: 4px;
  background-color: #fff;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  color: black;
  cursor: pointer;
  opacity: 0.9;
  svg {
    width: 12px;
    height: 12px;
  }
  &:hover {
    opacity: 1;
  }
`;
const TagInput = styled.input`
  flex-shrink: 1;
  border: 0;
  height: 34px;
`;
