import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import ConvertFileSelector, { CovertLogo } from './components/ConvertFileSelector';
import ConvertResult from './components/ConvertResult';
import TbInput from '../../../components/common/ui/form/TbInput';
import TbInputColor from '../../../components/common/ui/form/TbInputColor';
import TBInputRange from '../../../components/common/ui/form/TBInputRange';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import TbLoading from '../../../components/common/ui/TbLoading';

export interface ConverterOptions {
  name?: string;
  imageScale: number;
  // jpg options
  jpgBgOrigin: string;
  jpgBgWhite: string;
  jpgBgBlack: string;
  jpgMarginRatio: number;
}
interface ConvertResult {
  url: string;
  width: number;
  height: number;
}
const LogoConverter = () => {
  const [isGenerated, setIsGenerated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [logos, setLogos] = useState<CovertLogo[]>([]);

  // Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ConverterOptions>({
    defaultValues: {
      imageScale: 4,
      jpgBgOrigin: '#ffffff',
      jpgBgWhite: '#000000',
      jpgBgBlack: '#ffffff',
      jpgMarginRatio: 0.2,
    },
  });
  const watchBrandName = watch('name');
  const watchJpaMarginRatio = watch('jpgMarginRatio');
  const watchImageScale = watch('imageScale');

  /**
   * SVG 이미지 선택 완료시
   * */
  const onCompleteSVG = (logos: CovertLogo[]) => {
    setLogos(logos);
  };

  /**
   * SVG String Data To Blob Url
   * */
  const svgToBlobUrl = (svgData: string): ConvertResult => {
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgData, 'image/svg+xml');

    // 에러 검사를 추가하여 유효한 SVG 문서인지 확인
    const parserError = svgDoc.querySelector('parsererror');
    if (parserError) {
      console.error('Error parsing SVG:', parserError.textContent);
      throw 'Error parsing SVG XML. Please ensure it is a valid SVG.';
    }
    const svgElement = svgDoc.documentElement;
    const svgBlob = new Blob([new XMLSerializer().serializeToString(svgElement)], {
      type: 'image/svg+xml;charset=utf-8',
    });
    return {
      // SVG Size
      width: Number(svgElement.getAttribute('width')),
      height: Number(svgElement.getAttribute('height')),
      url: URL.createObjectURL(svgBlob),
    };
  };

  /**
   * SVG To PNG
   * */
  const svgToPng = async (svgUrl: string): Promise<ConvertResult> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const width = img.width * watchImageScale;
        const height = img.height * watchImageScale;
        canvas.width = width;
        canvas.height = height;
        ctx?.drawImage(img, 0, 0, width, height);

        // Revoke the object URL after the image is loaded
        URL.revokeObjectURL(svgUrl);

        resolve({
          url: canvas.toDataURL('image/png'),
          width,
          height,
        });
      };
      img.src = svgUrl;
    });
  };

  /**
   * SVG To JPG
   * */
  const svgToJpg = async (svgUrl: string, bg: string): Promise<ConvertResult> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const width = img.width * watchImageScale;
        const height = img.height * watchImageScale;
        const margin = (width * watchJpaMarginRatio) / 2;
        canvas.width = width + margin * 2;
        canvas.height = height + margin * 2;

        if (ctx) {
          ctx.fillStyle = bg || '#FFFFFF'; // JPG doesn't support transparency
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, margin, margin, width, height);
        }

        // Revoke the object URL after the image is loaded
        URL.revokeObjectURL(svgUrl);

        resolve({
          url: canvas.toDataURL('image/jpeg'),
          width,
          height,
        });
      };
      img.src = svgUrl;
    });
  };

  /**
   * Blob url To Base64
   * */
  const blogTOBase64 = async (blobUrl: string) => {
    return new Promise((resolve) => {
      fetch(blobUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = function () {
            const base64data = reader.result;
            resolve(base64data);
          };
          reader.readAsDataURL(blob);
        })
        .catch((error) => {
          console.error('Error fetching the blob:', error);
        });
    });
  };

  /**
   * 이미지 생성 트리거
   * */
  const onSubmit = async (data: ConverterOptions) => {
    if (!logos || logos.length === 0) {
      toast.warning('SVG 로고를 선택하세요.');
    }
    setIsLoading(true);

    try {
      const newLogos = await Promise.all(
        logos.map(async (logo) => {
          const { origin, white, black } = logo.svg;
          if (origin?.xml && white?.xml && black?.xml) {
            // Blob 데이터로 변환.
            const [svgBlobOrigin, svgBlobWhite, svgBlobBlack] = [
              svgToBlobUrl(origin.xml),
              svgToBlobUrl(white.xml),
              svgToBlobUrl(black.xml),
            ];

            const results = await Promise.all([
              // svg
              blogTOBase64(svgBlobOrigin?.url),
              blogTOBase64(svgBlobWhite?.url),
              blogTOBase64(svgBlobBlack?.url),
              // png
              svgToPng(svgBlobOrigin?.url),
              svgToPng(svgBlobWhite?.url),
              svgToPng(svgBlobBlack?.url),
              // jpg
              svgToJpg(svgBlobOrigin?.url, data.jpgBgOrigin),
              svgToJpg(svgBlobWhite?.url, data.jpgBgWhite),
              svgToJpg(svgBlobBlack?.url, data.jpgBgBlack),
            ]);

            return {
              ...logo,
              svg: {
                ...logo.svg,
                origin: {
                  ...logo.svg?.origin,
                  url: results[0],
                },
                white: {
                  ...logo.svg?.white,
                  url: results[1],
                },
                black: {
                  ...logo.svg?.black,
                  url: results[2],
                },
                width: svgBlobOrigin.width,
                height: svgBlobOrigin.height,
              },
              png: {
                ...logo.png,
                origin: {
                  ...logo.png?.origin,
                  url: results[3].url,
                },
                white: {
                  ...logo.png?.white,
                  url: results[4].url,
                },
                black: {
                  ...logo.png?.black,
                  url: results[5].url,
                },
                width: results[3].width,
                height: results[3].height,
              },
              jpg: {
                ...logo.jpg,
                origin: {
                  ...logo.jpg?.origin,
                  url: results[6].url,
                },
                white: {
                  ...logo.jpg?.white,
                  url: results[7].url,
                },
                black: {
                  ...logo.jpg?.black,
                  url: results[8].url,
                },
                width: results[6].width,
                height: results[6].height,
              },
            } as CovertLogo;
          }
        }),
      );
      setLogos(newLogos as CovertLogo[]);
      setIsGenerated(true);
    } catch (error) {
      console.error('Error during logo conversion:', error);
      toast.error('로고 변환 중 오류가 발생했습니다.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <Breadcrumb parent="도구 모음" title="로고 컨버터">
        <div className="mb-0"></div>
      </Breadcrumb>
      <Container fluid={true}>
        <Row>
          <Col sm={3}>
            <Card>
              <CardHeader>
                <h5>변환 설정</h5>
              </CardHeader>
              <CardBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <TbInput
                    label={'브랜드명'}
                    name={'name'}
                    placeholder={'ex. [브랜드명]_가로형_white.svg'}
                    type="text"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbInputColor
                    label={'JPG 원본 배경'}
                    name={'jpgBgOrigin'}
                    horizontal={true}
                    register={register}
                    errors={errors}
                  />
                  <TbInputColor
                    label={'JPG 화이트 배경'}
                    name={'jpgBgWhite'}
                    horizontal={true}
                    register={register}
                    errors={errors}
                  />
                  <TbInputColor
                    label={'JPG 블랙 배경'}
                    name={'jpgBgBlack'}
                    horizontal={true}
                    register={register}
                    errors={errors}
                  />
                  <TBInputRange
                    label={`JPG 여백 비율 (가로 기준 ${Math.round(Number(watchJpaMarginRatio) * 100)}%가 여백)`}
                    name={'jpgMarginRatio'}
                    min={0}
                    max={1}
                    step={0.05}
                    register={register}
                    errors={errors}
                  />
                  <TBInputRange
                    label={`변환 스케일 : x${watchImageScale}`}
                    name={'imageScale'}
                    min={1}
                    max={10}
                    step={1}
                    register={register}
                    errors={errors}
                  />
                  <Button type={'submit'} color={'primary'} block={true} disabled={isLoading} className={'text-center'}>
                    {isLoading ? (
                      <div className={'d-flex align-items-center justify-content-center'}>
                        Loading...
                        <span>
                          <TbLoading />
                        </span>
                      </div>
                    ) : (
                      'JPG, PNG, SVG 변환'
                    )}
                  </Button>
                </form>
              </CardBody>
            </Card>
          </Col>
          <Col sm={9}>
            <ConvertFileSelector onComplete={onCompleteSVG} />
            {isGenerated && <ConvertResult name={watchBrandName} logos={logos} />}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default React.memo(LogoConverter);
