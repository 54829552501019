import { TBFormGroup, TBLabel } from '../../styledComponents';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

interface DefaultProps {
  label?: string;
  message?: string | ReactElement;
  id?: string;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  horizontal?: boolean;
  register: any;
  errors: any;
  validation?: any;
  validate?: any;
}
const TbInputColor = ({
  label = '',
  message = '',
  name = '',
  id = '',
  disabled = false,
  horizontal = false,
  register = undefined,
  errors = {},
  validation = undefined,
  validate = undefined,
  onChange = undefined,
}: DefaultProps) => {
  return (
    <TBFormGroup horizontal={horizontal.toString()}>
      {label && (
        <TBLabel horizontal={horizontal.toString()}>
          {label}
          {validation?.required && <span className={'text-danger'}>(필수)</span>}
        </TBLabel>
      )}
      <div className={'w-100'}>
        <InputColor
          className={`form-control form-control-color ${errors[name] ? 'is-invalid' : ''}`}
          id={id}
          name={name}
          type={'color'}
          {...register(name, {
            ...validation,
            validate: validate,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              if (onChange) onChange(e);
            },
          })}
          autoComplete="true"
          disabled={disabled}
        />
        {errors[name] && (
          <div className="text-danger mt-1">
            {errors[name]?.message ? errors[name].message : `${label}을/를 입력하세요.`}
          </div>
        )}
        {message && <div className={'mt-1'}>{message}</div>}
      </div>
    </TBFormGroup>
  );
};
export default React.memo(TbInputColor);

const InputColor = styled.input``;
