import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { useQuery } from 'react-query';
import useApiError from '../../hooks/useApiError';
import { getCreditPage, ReqCreditPage } from '../../api/creditAPI';
import { creditColumns } from './data/credit-columns';
import Breadcrumb from '../../components/common/breadcrumb';
import { useNavigate, useSearchParams } from 'react-router-dom';
import TbFilter from '../../components/common/ui/TbFilter';
import { Col, Row } from 'reactstrap';
import TbSelect from '../../components/common/ui/form/TbSelect';
import TbInput from '../../components/common/ui/form/TbInput';
import { cellsStyle, headCellsStyle, rowsStyle } from '../../data/customizer/react-data-table-config';
import useDefaultFilter from '../../hooks/useDefaultFilter';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import { toURLSearchParams } from '../../utils/urlUtils';

const statusList: any[] = [
  { label: '적립', value: 'ACCUMULATE' },
  { label: '사용', value: 'USE' },
  { label: '만료', value: 'EXPIRE' },
  { label: '환불', value: 'REFUND' },
  { label: '취소', value: 'CANCEL' },
];

function CreditList() {
  const handleApiError = useApiError(); // 에러 처리 훅
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [defaultFilter] = useDefaultFilter();
  // Pagination
  const [listData, setListData] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [reqParams, setReqParams] = useState<ReqCreditPage>({
    ...defaultFilter,
    // Custom Filter
    status: (searchParams.get('status') as 'ACCUMULATE' | 'USE' | 'EXPIRE' | 'REFUND' | 'CANCEL') || '',
    idBrand: searchParams.get('idBrand') ? Number(searchParams.get('idBrand')) : undefined,
  });

  // reqParam 변경되는 경우, url의 쿼리스트링 업데이트
  useDidMountEffect(() => {
    // `null` 또는 `undefined` 제거하고 모든 값을 스트링으로 변환.
    setSearchParams(toURLSearchParams(reqParams));
  }, [reqParams, setSearchParams]);

  /**
   * 페이지 데이터 요청.
   * */
  const { isLoading } = useQuery(
    ['getCreditPage', { reqParams }],
    () => getCreditPage({ ...reqParams, idBrand: reqParams.idBrand || undefined }),
    {
      onSuccess: (data) => {
        setListData(data?._embedded?.credits || []);
        setTotalRows(data?.page?.totalElements | 0);
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 페이지 변경 핸들러
   * */
  const handlePageChange = (page: number) => {
    setReqParams({
      ...reqParams,
      page: page - 1,
    });
  };

  /**
   * 페이지 사이즈 변경
   * */
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParams({
      ...reqParams,
      size: newPerPage,
      page: page - 1,
    });
  };

  // TODO 크레딧 적립/차감
  // const handleCredit = (type: 'PLUS' | 'MINUS') => {
  //   if (type === 'PLUS') {
  //     console.log('Plus');
  //   } else if (type === 'MINUS') {
  //     console.log('Minus');
  //   }
  // };

  // 검색 핸들러
  const onSubmitSearch = (filters: any) => {
    setReqParams({
      ...reqParams,
      ...filters,
    });
  };

  // 행 클릭 핸들러
  const handleRowClicked = (row: any) => {
    navigate(`/admin/credit/update/${row.id}`);
  };

  return (
    <div>
      <Breadcrumb parent="크레딧" title="크레딧 내역">
        {/*<Link className="btn btn-primary" to={`/admin/workflow/update`}>*/}
        {/*  새로 등록*/}
        {/*</Link>*/}
      </Breadcrumb>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <TbFilter onSubmit={onSubmitSearch} defaultValues={reqParams}>
              {({ register, errors, setValue }) => {
                setValue('idBrand', reqParams.idBrand);
                setValue('status', reqParams.status);
                return (
                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col sm={2}>
                          <label className="col-form-label">브랜드 ID</label>
                        </Col>
                        <Col sm={10}>
                          <TbInput
                            name={'idBrand'}
                            placeholder={'브랜드 ID'}
                            type="text"
                            register={register}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col sm={2}>
                          <label className="col-form-label">상태</label>
                        </Col>
                        <Col sm={10}>
                          <TbSelect
                            placeholder={'전체'}
                            name="status"
                            options={statusList ? statusList : []}
                            register={register}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              }}
            </TbFilter>
          </div>
          <div className={'col-xl-12'}>
            <div className={'card-table-body'}>
              <DataTable
                noHeader
                columns={creditColumns}
                data={listData}
                progressPending={isLoading}
                highlightOnHover
                pointerOnHover
                pagination
                paginationServer
                paginationDefaultPage={(reqParams.page || 0) + 1}
                paginationTotalRows={totalRows}
                paginationPerPage={reqParams.size}
                paginationRowsPerPageOptions={[5, 10, 30, 50, 100]}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onRowClicked={handleRowClicked}
                dense={true}
                customStyles={{
                  rows: rowsStyle,
                  headCells: headCellsStyle,
                  cells: cellsStyle,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(CreditList);
