import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import useApiError from '../../hooks/useApiError';
import Breadcrumb from '../../components/common/breadcrumb';
import TbFilter from '../../components/common/ui/TbFilter';
import { Col, Row } from 'reactstrap';
import TbInput from '../../components/common/ui/form/TbInput';
import TbSelect from '../../components/common/ui/form/TbSelect';
import DataTable from 'react-data-table-component';
import { getPaymentList, ReqPaymentList } from '../../api/paymentAPI';
import { paymentColumns } from './data/payment-columns';
import { cellsStyle, headCellsStyle, rowsStyle } from '../../data/customizer/react-data-table-config';
import useDefaultFilter from '../../hooks/useDefaultFilter';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import { toURLSearchParams } from '../../utils/urlUtils';

const orderStatusList: any[] = [
  { label: '결제 대기', value: 'INIT' },
  { label: '결제 완료', value: 'PAID' },
  { label: '결제 실패', value: 'FAILED' },
  { label: '환불 완료', value: 'REFUND' },
];

function PaymentList() {
  const handleApiError = useApiError(); // 에러 처리 훅
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [defaultFilter] = useDefaultFilter();
  // Pagination
  const [listData, setListData] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [reqParams, setReqParams] = useState<ReqPaymentList>({
    ...defaultFilter,
    // Custom Filter
    orderStatus: (searchParams.get('orderStatus') as 'INIT' | 'PAID' | 'FAILED' | 'REFUND') || '',
    pid: searchParams.get('pid') || '',
    idSubscription: searchParams.get('idSubscription') ? Number(searchParams.get('idSubscription')) : undefined,
  });

  // reqParam 변경되는 경우, url의 쿼리스트링 업데이트
  useDidMountEffect(() => {
    // `null` 또는 `undefined` 제거하고 모든 값을 스트링으로 변환.
    setSearchParams(toURLSearchParams(reqParams));
  }, [reqParams, setSearchParams]);

  /**
   * 페이지 데이터 요청.
   * */
  const { isLoading } = useQuery(
    ['getPaymentList', { reqParams }],
    () => getPaymentList({ ...reqParams, idSubscription: reqParams.idSubscription || undefined }),
    {
      onSuccess: (data) => {
        setListData(data?._embedded?.payments || []);
        setTotalRows(data?.page?.totalElements | 0);
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 페이지 변경 핸들러
   * */
  const handlePageChange = (page: number) => {
    setReqParams({
      ...reqParams,
      page: page - 1,
    });
  };

  /**
   * 페이지 사이즈 변경
   * */
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParams({
      ...reqParams,
      size: newPerPage,
      page: page - 1,
    });
  };

  // 검색 핸들러
  const onSubmitSearch = (filters: any) => {
    setReqParams({
      ...reqParams,
      ...filters,
    });
  };

  // 행 클릭 핸들러
  const handleRowClicked = (row: any) => {
    navigate(`/admin/payment/read/${row.id}`);
  };

  return (
    <div>
      <Breadcrumb parent="결제 내역" title="결제 내역"></Breadcrumb>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <TbFilter onSubmit={onSubmitSearch} defaultValues={reqParams}>
              {({ register, errors, setValue }) => {
                setValue('idSubscription', reqParams.idSubscription);
                setValue('orderStatus', reqParams.orderStatus);
                setValue('pid', reqParams.pid);
                return (
                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col sm={2}>
                          <label className="col-form-label">구독 ID</label>
                        </Col>
                        <Col sm={10}>
                          <TbInput
                            name={'idSubscription'}
                            placeholder={'구독 ID'}
                            type="text"
                            register={register}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={2}>
                          <label className="col-form-label">결제 타입</label>
                        </Col>
                        <Col sm={10}>
                          <TbSelect
                            placeholder={'전체'}
                            name="orderStatus"
                            options={orderStatusList ? orderStatusList : []}
                            register={register}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col sm={2}>
                          <label className="col-form-label">결제 번호</label>
                        </Col>
                        <Col sm={10}>
                          <TbInput
                            name={'pid'}
                            placeholder={'결제 번호'}
                            type="text"
                            register={register}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              }}
            </TbFilter>
          </div>
          <div className={'col-xl-12'}>
            <div className={'card-table-body'}>
              <DataTable
                noHeader
                columns={paymentColumns}
                data={listData}
                progressPending={isLoading}
                highlightOnHover
                pointerOnHover
                pagination
                paginationServer
                paginationDefaultPage={(reqParams.page || 0) + 1}
                paginationTotalRows={totalRows}
                paginationPerPage={reqParams.size}
                paginationRowsPerPageOptions={[5, 10, 30, 50, 100]}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onRowClicked={handleRowClicked}
                dense={true}
                customStyles={{
                  rows: rowsStyle,
                  headCells: headCellsStyle,
                  cells: cellsStyle,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(PaymentList);
