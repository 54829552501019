import styled, { css } from 'styled-components';
import React, { useEffect, useState } from 'react';
import useApiError from '../../../../../hooks/useApiError';
import { useMutation } from 'react-query';
import { resourceDeleteFile, resourceUploadFile, resourceUploadImage } from '../../../../../api/fileAPI';
import TbLoading from '../../TbLoading';
import { File, Trash2 } from 'react-feather';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import TbModal from '../../TbModal';

interface DefaultProps {
  type: 'FILE' | 'IMAGE';
  path?: string; // 업로드 할 경로.
  fileName?: string; // 저장될 파일 이름(없는 경우 랜덤값).
  file?: File; // 업로드 할 파일.
  url?: string; // 업로드 된 파일 경로.
  isUploaded: boolean; // 업로드 완료 여부.
  onComplete?: (url: string) => void; // 업로드 완료.
  onDeleteComplete?: (url: string) => void; // 삭제 완료.
}

/**
 * 파일을 업로드 하고 결과를 리턴한다.
 * */
const TbResourceFileUploadItem = ({
  type,
  path = '',
  fileName = '',
  file,
  url,
  isUploaded,
  onComplete,
  onDeleteComplete,
}: DefaultProps) => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const uploadFileMutation = useMutation(resourceUploadFile);
  const uploadImageMutation = useMutation(resourceUploadImage);
  const deleteFileMutation = useMutation(resourceDeleteFile);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // props 가 전달 되면 바로 업로드를 시작한다.
  useEffect(() => {
    if (file && !isUploaded) {
      setIsLoading(true);
      const mutate = async () => {
        try {
          const reqBody = {
            file: file,
            body: {
              path: path,
              fileName: fileName,
            },
          };
          const resultData =
            type === 'IMAGE'
              ? await uploadImageMutation.mutateAsync(reqBody)
              : await uploadFileMutation.mutateAsync(reqBody);
          onComplete?.(resultData);
        } catch (error) {
          handleApiError(error);
        } finally {
          setIsLoading(false);
        }
      };
      mutate();
    }
  }, [file, isUploaded]);

  const handleOnDelete = async () => {
    if (url) {
      try {
        await deleteFileMutation.mutateAsync(url);
        onDeleteComplete?.(url);
        toast.success('파일이 삭제되었습니다.');
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  /**
   * 삭제 모달 토글
   * */
  const toggleDeleteModal = () => {
    setIsDeleteModal(!isDeleteModal);
  };

  // TODO 이미지 or 파일 확장자에 따라 다르게 표기
  return (
    <Container>
      <TbModal isOpen={isDeleteModal} onClickConfirm={handleOnDelete} toggle={toggleDeleteModal}>
        <h5 className="text-center m-0">삭제 하시겠습니까?</h5>
      </TbModal>
      {type === 'IMAGE' && (
        <Thumbnail href={url} target={'_blank'} bgUrl={type === 'IMAGE' ? url : ''}>
          {isLoading && (
            <LoadingWrap>
              <TbLoading size={26} color={'#fff'} />
            </LoadingWrap>
          )}

          <BtnDelete
            onClick={(e: MouseEvent) => {
              e.preventDefault();
              toggleDeleteModal();
            }}
            className={'btn btn-danger btn-xs'}
          >
            <Trash2 size={16} />
          </BtnDelete>
        </Thumbnail>
      )}

      {type === 'FILE' && (
        <FileWrap>
          <FileIconWrap size={34} />
          <FileName>
            <a href={url} target={'_blank'} rel="noreferrer">
              {url}
            </a>{' '}
          </FileName>
          <BtnDelete
            onClick={(e: MouseEvent) => {
              e.preventDefault();
              toggleDeleteModal();
            }}
            className={'btn btn-danger btn-xs'}
          >
            <Trash2 size={16} />
          </BtnDelete>
        </FileWrap>
      )}
    </Container>
  );
};

export default React.memo(TbResourceFileUploadItem);

const Container = styled.div<{ bgUrl?: string }>`
  position: relative;
  display: flex;
`;

const Thumbnail = styled.a<{ bgUrl?: string }>`
  position: relative;
  width: 120px;
  height: 120px;
  border: 1px solid #dee2e6;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 8px;
  ${({ bgUrl }) =>
    bgUrl &&
    css`
      background-image: url(${bgUrl});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    `}
`;

const LoadingWrap = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const BtnDelete = styled(Button)`
  position: absolute;
  top: 8px;
  right: 8px;
  line-height: 1;
`;
const FileWrap = styled.div`
  border: 1px solid #dee2e6;
  padding-right: 46px;
  margin-bottom: 8px;
  width: 250px;
  height: 50px;
  display: flex;
  align-items: center;

  ${BtnDelete} {
    top: 50%;
    margin-top: -14px;
  }
`;
const FileIconWrap = styled(File)`
  flex-basis: 50px;
  min-width: 50px;
  height: 100%;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
`;
const FileName = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 표시할 줄 수 */
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5; /* 각 줄의 높이 */
  max-height: calc(1.5em * 2); /* line-height * 줄 수로 최대 높이 설정 */
  font-size: 12px;
  margin-left: 12px;
  margin-right: 12px;
  padding: 4px 8px;
`;
