import React, { Fragment, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useQuery } from 'react-query';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Breadcrumb from '../../../../components/common/breadcrumb';
import { getTaskProductCategoryPage } from '../../../../api/taskAPI';
import useApiError from '../../../../hooks/useApiError';
import { CategoryTaskColumns } from '../../data/task-product-columns';
import TbFilter from '../../../../components/common/ui/TbFilter';
import { cellsStyle, headCellsStyle, rowsStyle } from '../../../../data/customizer/react-data-table-config';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import { toURLSearchParams } from '../../../../utils/urlUtils';
import useDefaultFilter from '../../../../hooks/useDefaultFilter';

const ProductCategoryList = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const [defaultFilter] = useDefaultFilter();
  const [pageInfoData, setPageInfoData] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  //  state
  const [reqParam, setReqParam] = useState({
    ...defaultFilter,
  });

  // reqParam 변경되는 경우, url의 쿼리스트링 업데이트
  useDidMountEffect(() => {
    // `null` 또는 `undefined` 제거하고 모든 값을 스트링으로 변환.
    setSearchParams(toURLSearchParams(reqParam));
  }, [reqParam, setSearchParams]);

  /**
   * 목록 조회.
   * */
  const { isLoading } = useQuery(
    ['getTaskProductCategoryPage', { reqParam }],
    () => getTaskProductCategoryPage(reqParam),
    {
      onSuccess: (data) => {
        setPageInfoData(data._embedded?.categories || []);
        setTotalRows(data?.page?.totalElements || 0);
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  // 페이지 변경 핸들러
  const handlePageChange = (page: number) => {
    setReqParam({
      ...reqParam,
      page: page - 1,
    });
  };

  // 한 페이지 당 보여줄 행 수 변경 핸들러
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParam({
      ...reqParam,
      size: newPerPage,
      page: page - 1,
    });
  };

  // =================== 공통 항목 끝 ==================  //

  // 검색 핸들러
  const onSubmitSearch = (filters: any) => {
    setReqParam({
      ...reqParam,
      ...filters,
    });
  };

  //  행 클릭 핸들러
  const handleRowClicked = (row: any) => {
    navigate(`/admin/task/product/category/update/${row.id}`);
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <Breadcrumb parent="디자이너 작업" title="작업 카테고리 목록">
              <Link className="btn btn-primary" to={`/admin/task/product/category/update`}>
                새로 등록
              </Link>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          {/* :::::::::: Filter : Start ::::::::::  */}
          <div className="col-xl-12">
            <TbFilter onSubmit={onSubmitSearch} defaultValues={reqParam} />
          </div>
          {/* :::::::::: Filter : End ::::::::::  */}
          {/*  :::::::::: List ::::::::::  */}
          <div className="col-xl-12 mb-4">
            <DataTable
              noHeader
              columns={CategoryTaskColumns}
              highlightOnHover
              pointerOnHover
              pagination
              paginationServer
              data={pageInfoData}
              progressPending={isLoading}
              paginationDefaultPage={(reqParam.page || 0) + 1}
              paginationTotalRows={totalRows}
              paginationPerPage={reqParam.size}
              paginationRowsPerPageOptions={[5, 10, 30, 50, 100]}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              onRowClicked={handleRowClicked}
              dense={true}
              customStyles={{
                rows: rowsStyle,
                headCells: headCellsStyle,
                cells: cellsStyle,
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProductCategoryList;
