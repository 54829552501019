export interface SelectItem {
  label: string;
  value: any;
}

/**
 * Key:Value 형식의 enum 타입을 select 리스트 형식으로 변환.
 * */
export const enumToSelect = (list: Record<string, string>): SelectItem[] => {
  if (list) {
    return Object.entries(list).map(([key, value]) => ({
      value: key,
      label: value,
    }));
  }
  return [];
};
