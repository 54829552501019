import React, { Fragment, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import useApiError from '../../hooks/useApiError';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import { deletePayment, getPayment, syncPayment } from '../../api/paymentAPI';
import moment from 'moment/moment';
import ManageBrand from '../subscription/components/ManageBrand';
import { toast } from 'react-toastify';

const ReadPayment: React.FC = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const navigate = useNavigate();
  const deleteMutation = useMutation(deletePayment);
  const syncMutation = useMutation(syncPayment);
  const { id: idPayment } = useParams<{ id: string }>();
  const [dropdownOpen, setOpen] = useState(false);

  // Update 일떄 초기 데이터 로드
  const { data: defaultData, refetch } = useQuery(['getPayment', idPayment], () => getPayment(idPayment as string), {
    onError: (error) => {
      handleApiError(error);
    },
  });

  // =================== 공통 항목 끝 ==================  //

  /**
   * 드롭다운 토클
   * */
  const dropdownToggle = () => {
    setOpen(!dropdownOpen);
  };

  /**
   * 결제 내역 동기화
   * */
  const handleSync = useCallback(async () => {
    if (defaultData?.id) {
      try {
        await syncMutation.mutateAsync(defaultData.id);
        refetch();
        toast.success('PG사 데이터와 동기화 되었습니다.');
      } catch (error) {
        handleApiError(error);
      }
    }
  }, [defaultData?.id]);

  /**
   * 결제 내역 삭제
   * */
  const handleDelete = useCallback(async () => {
    if (defaultData?.id) {
      if (defaultData?.orderStatus !== 'INIT') {
        toast.warn('결제 대기 상태만 삭제 가능합니다.');
        return;
      }
      // TODO 대기 상태에서만 삭제 가능.(관리자만 삭제 가능)
      try {
        if (confirm('정말 삭제 하시겠습니까?')) {
          await deleteMutation.mutateAsync(defaultData.id);
          toast.success('결제 내역이 삭제되었습니다.');
          navigate(-1);
        }
      } catch (error) {
        handleApiError(error);
      }
    }
  }, [defaultData?.id]);

  // R ea d
  return (
    <Fragment>
      <Breadcrumb parent="결제 내역" title="결제 내역 상세">
        <div className="mb-0">
          <Button onClick={() => navigate(-1)} color="primary">
            목록
          </Button>
        </div>
      </Breadcrumb>
      <Container fluid={true}>
        <Row>
          <Col lg={4}>
            {/****** 브랜드/유저 정보 ******/}
            <ManageBrand idBrand={defaultData?.subscription?.idBrand} />
          </Col>

          <Col lg={8}>
            {/* ::::::::::::: 결제 내역 ::::::::::: */}
            <div className="card">
              <div className={'card-header'}>
                <h5>결제 내역</h5>
                <Dropdown isOpen={dropdownOpen} toggle={dropdownToggle}>
                  <DropdownToggle size={'sm'} color="dark" outline={true}>
                    <span>
                      작업 <i className="fa fa-caret-down"></i>
                    </span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={handleSync}>PG사 동기화</DropdownItem>
                    <DropdownItem onClick={handleDelete}>내역 삭제</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
              <div className={'card-body'}>
                <Row className="details">
                  <Col xs="6" className={'detail-item'}>
                    PID(결제번호)
                    <br />
                    <span>{defaultData?.pid}</span>
                  </Col>
                  {defaultData?.subscription?.id && (
                    <Col xs="6" className={'detail-item'}>
                      구독 ID
                      <br />
                      <span>{defaultData?.subscription?.id}</span>
                    </Col>
                  )}
                </Row>
                <hr />
                <Row className="details">
                  <Col xs="6" className={'detail-item'}>
                    결제 내용
                    <br />
                    <span>{defaultData?.title}</span>
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    결제 금액
                    <br />
                    <span>{defaultData?.amount.toLocaleString()}원</span>
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    결제일
                    <br />
                    <span>
                      {defaultData?.paymentDate
                        ? `${moment(defaultData?.paymentDate).format('YYYY/MM/DD HH:mm:ss')}`
                        : '-'}
                    </span>
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    결제 상태
                    <br />
                    <div>
                      {defaultData?.orderStatus === 'INIT' && (
                        <span className={'btn btn-xs btn-info text-white'}>결제 대기</span>
                      )}
                      {defaultData?.orderStatus === 'PAID' && (
                        <span className={'btn btn-xs btn-success text-white'}>결제 완료</span>
                      )}
                      {defaultData?.orderStatus === 'FAILED' && (
                        <span className={'btn btn-xs btn-danger text-white'}>결제 실패</span>
                      )}
                      {defaultData?.orderStatus === 'REFUND' && (
                        <span className={'btn btn-xs btn-warning text-white'}>환불 완료</span>
                      )}
                    </div>
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    결제 방법
                    <br />
                    <div>
                      {defaultData?.type === 'CARD' && (
                        <span className={'btn btn-xs btn-default'}>
                          신용카드({defaultData?.brand} ****-{defaultData?.lastNumber})
                        </span>
                      )}
                      {defaultData?.type === 'CASH' && <span className={'btn btn-xs btn-default'}>현금</span>}
                      {defaultData?.type === 'CREDIT' && <span className={'btn btn-xs btn-default'}>크레딧</span>}
                      {defaultData?.type === 'COUPON' && <span className={'btn btn-xs btn-default'}>쿠폰</span>}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            {/* ::::::::::::: 세부 내역 상세 ::::::::::: */}
            <div className="card">
              <div className={'card-header'}>
                <h5>세부 내역 상세</h5>
              </div>
              <div className={'card-body'}>
                {!defaultData?.target && <p className={`text-center m-5-15 text-danger`}>세부 내역이 없습니다.</p>}
                {defaultData?.target === 'SUBSCRIBE' && (
                  <>
                    <Row className="details">
                      <Col xs="6" className={'detail-item'}>
                        납입 주기
                        <br />
                        <span>
                          {defaultData?.periodStartDate
                            ? moment(defaultData?.periodStartDate).format('YYYY/MM/DD')
                            : ''}
                          &nbsp;~&nbsp;
                          {defaultData?.periodEndDate ? moment(defaultData?.periodEndDate).format('YYYY/MM/DD') : ''}
                        </span>
                      </Col>
                      <Col xs="6" className={'detail-item'}>
                        약정 기간(계약 기간)
                        <br />
                        <span>
                          {defaultData?.startDate ? moment(defaultData?.startDate).format('YYYY/MM/DD') : ''}
                          &nbsp;~&nbsp;
                          {defaultData?.endDate ? moment(defaultData?.endDate).format('YYYY/MM/DD') : ''}
                        </span>
                      </Col>
                      <Col xs="6" className={'detail-item'}>
                        결제 주기
                        <br />
                        <span>{defaultData?.periodType}</span>
                      </Col>
                      <Col xs="6" className={'detail-item'}>
                        멤버 추가 사용량
                        <br />
                        <span>{defaultData?.memberUsage} 계정</span>
                      </Col>
                      <Col xs="6" className={'detail-item'}>
                        멤버 계정당 요금
                        <br />
                        <span>{defaultData?.priceOfMember.toLocaleString()} 원/계정</span>
                      </Col>
                      <Col xs="6" className={'detail-item'}>
                        총 할인율
                        <br />
                        <span>{defaultData?.discountRate * 100}%</span>
                      </Col>
                    </Row>
                    <hr />
                  </>
                )}
                {(defaultData?.target === 'SUBSCRIBE' || defaultData?.target === 'CREDIT') && (
                  <>
                    <Row className="details">
                      <Col xs="6" className={'detail-item'}>
                        크레딧 지급
                        <br />
                        <span>{defaultData?.creditAmount.toLocaleString()} 크레딧</span>
                      </Col>
                      <Col xs="6" className={'detail-item'}>
                        크레딧 할인율
                        <br />
                        <span>{defaultData?.creditDiscountRate * 100}%</span>
                      </Col>
                    </Row>
                  </>
                )}
                {defaultData?.target === 'LOGO' && (
                  <>
                    <Row className="details">
                      <Col xs="6" className={'detail-item'}>
                        납입 주기
                        <br />
                        <span>
                          {defaultData?.periodStartDate
                            ? moment(defaultData?.periodStartDate).format('YYYY/MM/DD')
                            : ''}
                          &nbsp;~&nbsp;
                          {defaultData?.periodEndDate ? moment(defaultData?.periodEndDate).format('YYYY/MM/DD') : ''}
                        </span>
                      </Col>
                      <Col xs="6" className={'detail-item'}>
                        약정 기간(계약 기간)
                        <br />
                        <span>
                          {defaultData?.startDate ? moment(defaultData?.startDate).format('YYYY/MM/DD') : ''}
                          &nbsp;~&nbsp;
                          {defaultData?.endDate ? moment(defaultData?.endDate).format('YYYY/MM/DD') : ''}
                        </span>
                      </Col>
                      <Col xs="6" className={'detail-item'}>
                        결제 주기
                        <br />
                        <span>{defaultData?.periodType}</span>
                      </Col>
                    </Row>
                  </>
                )}
                <Row className="details">
                  <Col xs="12" className={'detail-item'}>
                    관리자 메모
                    <br />
                    <span style={{ whiteSpace: 'pre-line' }}>{defaultData?.memo}</span>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <br />
      </Container>
    </Fragment>
  );
};

export default ReadPayment;
