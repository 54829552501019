import React, { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';

const PreviewImageFile = forwardRef(({ file }: { file: File }, ref: React.Ref<HTMLImageElement>) => {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  useEffect(() => {
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = () => setPreviewUrl(reader.result as string);
      reader.readAsDataURL(file);
    } else {
      setPreviewUrl(null); // 이미지 파일이 아닌 경우 미리보기 초기화
    }
  }, []);

  if (previewUrl) {
    return <Image ref={ref} src={previewUrl} alt="미리보기" />;
  }
  return null;
});
PreviewImageFile.displayName = 'PreviewImageFile';
export default React.memo(PreviewImageFile);

const Image = styled.img`
  width: 100%;
  max-width: 100%;
`;
