import APIClient from './APIClient';
import { PageInfo } from './types';

export interface ReqProduct {
  name: string;
  locale: string; // TODO 타입 지정
  currency: string; // TODO 타입 지정
  cost: number;
  discountRate: number;
  orderAscending: number;
  active: boolean;
}

export interface ResProduct {
  id: number;
  name: string;
  locale: string; // TODO 타입 지정
  currency: string; // TODO 타입 지정
  cost: number;
  price: number;
  discountRate: number;
  orderAscending: number;
  active: boolean;
  createdDate?: string;
  updatedDate?: string;
}

export interface ResProductList {
  id: number;
  name: string;
  locale: string; // TODO 타입 지정
  currency: string; // TODO 타입 지정
  cost: number;
  price: number;
  discountRate: number;
  active: boolean;
}

export interface ReqProductList extends PageInfo {
  active?: boolean | undefined;
}

/**
 * [Product] 페이징
 * */
export const getProductPage = async (params: ReqProductList) => {
  const response = await APIClient.get('/v2/api/product/page', {
    params,
  });
  return response.data;
};

/**
 * [Product] 단일 조회
 * */
export const getProduct = async (idProduct: number) => {
  const response = await APIClient.get(`/v2/api/product/${idProduct}`);
  return response.data;
};

/**
 * [Product] 생성
 * */
export const createProduct = async (product: ReqProduct) => {
  const response = await APIClient.post('/v2/api/product', product);
  return response.data;
};

/**
 * [Product] 수정
 * */
export const updateProduct = async ({ idProduct, body }: { idProduct: number; body: ReqProduct }) => {
  const response = await APIClient.patch(`/v2/api/product/${idProduct}`, body);
  return response.data;
};

/**
 * [Product] 삭제
 * */
export const deleteProduct = async (idProduct: number) => {
  const response = await APIClient.delete(`/v2/api/product/${idProduct}`);
  return response.data;
};
