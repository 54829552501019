import React from 'react';
import { SelectItem } from '../../../utils/enumUtils';

export const searchKeywordColumns = (listEntityType?: SelectItem[]) => [
  {
    name: 'id',
    selector: (row: any) => row.idSearchKeyword,
    center: true,
    width: '60px',
  },
  {
    name: '검색어 이름',
    selector: (row: any) => row.name,
    center: true,
    wrap: true,
  },
  {
    name: '타입',
    selector: (row: any) => {
      const selectedItem = listEntityType?.find((item: any) => item.value === row.entityType);
      return selectedItem?.label || '';
    },
    center: true,
    wrap: true,
    width: '160px',
  },
  {
    name: '활성 여부',
    selector: (row: any) => row.active,
    cell: (row: any) => {
      return row.active ? (
        <span className="badge badge-success">활성</span>
      ) : (
        <span className="badge badge-danger">비활성</span>
      );
    },
    sortable: true,
    center: true,
    wrap: true,
    width: '80px',
  },
];
