import React, { Fragment, useMemo, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Row } from 'reactstrap';
import Breadcrumb from '../../components/common/breadcrumb';
import TbLoading from '../../components/common/ui/TbLoading';
import { useNavigate, useParams } from 'react-router-dom';
import TbModal from '../../components/common/ui/TbModal';
import { toast } from 'react-toastify';
import useApiError from '../../hooks/useApiError';
import { useMutation, useQuery } from 'react-query';
import { createFontFamily, deleteFontFamily, getFontFamily, ReqFontFamily, updateFontFamily } from '../../api/fontAPI';
import { useForm } from 'react-hook-form';
import TbInput from '../../components/common/ui/form/TbInput';
import TbInputTag from '../../components/common/ui/form/TbInputTag';
import TbRadioBoolean from '../../components/common/ui/form/TbRadioBoolean';
import TbDateText from '../../components/common/ui/TbDateText';
import TbCheckbox from '../../components/common/ui/form/TbCheckbox';
import TbSelect from '../../components/common/ui/form/TbSelect';
import FontFaceList from './components/FontFaceList';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import TbInputUploadImage from '../../components/common/ui/form/file/TbResourceFileUpload';
import { getSupportLanguage } from '../../api/settingAPI';

const FontFamilyUpdate = () => {
  const userRole = useSelector((state: RootState) => state.user.userRole);
  const navigate = useNavigate();
  const handleApiError = useApiError(); // 에러 처리 훅
  const { idFontFamily } = useParams<{ idFontFamily: string }>();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const deleteMutate = useMutation(deleteFontFamily);
  const createMutate = useMutation(createFontFamily);
  const updateMutate = useMutation(updateFontFamily);
  const [isGoogleFonts, setIsGoogleFonts] = useState<boolean>(false);
  const fontType = [
    { label: 'Serif', value: 'serif' },
    { label: 'Sans Serif', value: 'sans-serif' },
    { label: 'Cursive', value: 'cursive' },
    { label: 'Monospace', value: 'monospace' },
    { label: 'Fantasy', value: 'fantasy' },
  ];

  // Form
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ReqFontFamily>({
    defaultValues: {
      ...(!idFontFamily
        ? // 생성일 경우 초기값.
          {
            cssPath: '(자동생성)',
            top: false,
            active: false,
          }
        : {}),
    },
  });

  const watchName = watch('name');
  const watchImage = watch('image');

  /**
   * 지원 언어 목록 조회
   * */
  const { data: supportLanguages } = useQuery(['getSupportLanguage'], () => getSupportLanguage(), {
    select: (data) => {
      return Object.entries(data).map(([, value]) => ({
        value: value,
        label: value,
      }));
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 업데이트인 경우, 초기 데이터 로드
   * */
  const { data: defaultData } = useQuery(['getFontFamily', idFontFamily], () => getFontFamily(Number(idFontFamily)), {
    enabled: !!idFontFamily && !!supportLanguages,
    select: (data) => {
      return {
        ...data,
      };
    },
    onSuccess: (data) => {
      setValue('name', data.name);
      setValue('displayName', data.displayName);
      setValue('filePath', data.filePath);
      setValue('image', data.image);
      setValue('languageList', data.languageList);

      setValue('css', data.css);
      setValue('cssPath', data.cssPath);

      setValue('handwriting', data.handwriting);
      setValue('cursive', data.cursive);
      setValue('serif', data.serif);
      setValue('gothic', data.gothic);
      setValue('round', data.round);
      setValue('design', data.design);
      setValue('headerFont', data.headerFont);
      setValue('bodyFont', data.bodyFont);

      setValue('tagList', data.tagList);
      setValue('top', data.top);
      setValue('active', data.active);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 폼 요청.
   * */
  const onSubmit = async (data: any) => {
    setIsLoading(false);
    if (idFontFamily && Number(idFontFamily) > 0) {
      //Update
      try {
        await updateMutate.mutateAsync({
          idFontFamily: Number(idFontFamily),
          body: { ...data } as ReqFontFamily,
        });
        toast.success('요소 수정이 완료되었습니다.');
        navigate(-1);
      } catch (error) {
        handleApiError(error);
      }
    } else {
      // Create
      try {
        const fontFileName = watchName?.replace(/\s+/g, '');
        await createMutate.mutateAsync({
          ...data,
          fileName: fontFileName,
        });
        toast.success('요소가 생성되었습니다.');
        navigate(-1);
      } catch (error) {
        handleApiError(error);
      }
    }

    setIsLoading(false);
  };

  /**
   * 삭제 요청 핸들러
   * */
  const onSubmitDelete = async () => {
    if (idFontFamily) {
      try {
        await deleteMutate.mutateAsync(Number(idFontFamily));
        navigate(-1);
        toggleDeleteModal();
        toast.success('요소가 삭제되었습니다.');
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  /**
   * 삭제 모달 토글
   * */
  const toggleDeleteModal = () => {
    if (defaultData?.fontFaces && defaultData.fontFaces.length > 0) {
      toast.warning('모든 폰트페이스를 삭제해주세요.');
      return;
    }
    setIsDeleteModal(!isDeleteModal);
  };

  /**
   * 매니저 권한
   * */
  const isManager = useMemo(() => {
    return userRole !== 'ROLE_SUPER' && userRole !== 'ROLE_ADMIN';
  }, [userRole]);

  /**
   * 업로드 파일의 공통 URL
   * 호스트, 기본경로(service/fonts), 폰트 파일 이름 의 조합으로 고유 경로가 생성되기 때문에 아래와 같이 예측 가능.
   * */
  const uploadFileUrl = useMemo(() => {
    return `${process.env.REACT_APP_SERVICE_RESOURCES_URL}/${defaultData?.filePath}`;
  }, [defaultData?.filePath]);

  return (
    <Fragment>
      <TbModal isOpen={isDeleteModal} onClickConfirm={onSubmitDelete} toggle={toggleDeleteModal}>
        <h5 className="text-center m-0">삭제 하시겠습니까?</h5>
      </TbModal>
      <Container fluid={true}>
        <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
          <Breadcrumb parent="폰트 패밀리" title={`폰트 패밀리 ${idFontFamily ? '수정' : '생성'}`}>
            <div className="mb-0">
              {idFontFamily ? (
                <Button onClick={toggleDeleteModal} color="danger" className="me-3" type={'button'}>
                  삭제
                </Button>
              ) : (
                ''
              )}

              <Button color="secondary" className="me-3" type="submit">
                {isLoading ? <TbLoading /> : idFontFamily ? '수정' : '생성'}
              </Button>
              <Button onClick={() => navigate(-1)} color="primary">
                목록
              </Button>
            </div>
          </Breadcrumb>

          <Row>
            <Col sm={7}>
              <Card>
                <CardHeader>
                  <h5>폰트 정보</h5>
                </CardHeader>
                <CardBody>
                  <TbInput
                    label="공식 폰트패밀리 이름"
                    message={
                      <span className={'text-danger'}>
                        * 최초 생성 후 수정 불가합니다.
                        <br />* 폰트 개발사에서 제공하는 공식 폰트 패밀리 이름으로 입력하세요.
                      </span>
                    }
                    name={'name'}
                    type="text"
                    readonly={!!idFontFamily}
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbInput
                    label="폰트패밀리 표기명"
                    name={'displayName'}
                    type="text"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />

                  {idFontFamily ? (
                    <>
                      {/* 업데이트 시에만 표시*/}

                      <TbInput
                        label="폰트파일 경로"
                        name={'filePath'}
                        type="text"
                        register={register}
                        errors={errors}
                        readonly={isManager}
                        validation={{ required: true }}
                      />
                    </>
                  ) : (
                    <>
                      {/* 최초 생성 시에만 표시*/}

                      <TbCheckbox
                        name={'isGoogle'}
                        checked={isGoogleFonts}
                        type={'checkbox'}
                        label={'구글 폰트인 경우 체크'}
                        horizontal={true}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setIsGoogleFonts(isChecked);
                          setValue('cssPath', isChecked ? '' : '(자동 생성)');
                        }}
                      />
                      {!isGoogleFonts && (
                        <TbSelect
                          label="폰트 유형"
                          options={fontType}
                          onChange={(e) => {
                            const type = e.target.value;
                            if (type && watchName) {
                              setValue('css', `'${watchName}', ${type}`);
                            }
                          }}
                        />
                      )}
                    </>
                  )}

                  <TbInput
                    label="CSS 폰트 패밀리"
                    name={'css'}
                    type="text"
                    readonly={
                      (!idFontFamily && !isGoogleFonts) || // 최초 생성일 경우만 읽기 옵션(구글 폰트일 경우)
                      (!!idFontFamily && isManager)
                    }
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />

                  <TbInput
                    label="CSS 파일 경로"
                    name={'cssPath'}
                    type="text"
                    readonly={
                      (!idFontFamily && !isGoogleFonts) || // 최초 생성일 경우만 읽기 옵션(구글 폰트일 경우)
                      (!!idFontFamily && isManager) // 수정 시 매니저는 읽기만
                    }
                    register={register}
                    errors={errors}
                    validation={{ required: isGoogleFonts }}
                  />

                  <TbInputUploadImage
                    type={'FILE'}
                    path={`service/fonts/${defaultData?.fileName}`} // s3의 element 경로에 업로드
                    fileName={`${defaultData?.fileName}.png`}
                    control={control}
                    label={'대표 썸네일'}
                    message={'* 1MB 이하 PNG 형식만 업로드 가능합니다.'}
                    accept={'.png'}
                    maxSize={1}
                    initialData={
                      watchImage
                        ? `${process.env.REACT_APP_SERVICE_RESOURCES_URL}/${defaultData?.filePath}/${watchImage}`
                        : ''
                    }
                    onComplete={() => {
                      setValue('image', `${defaultData?.fileName}.png`);
                    }}
                    onDeleteComplete={() => {
                      setValue('image', '');
                    }}
                    errors={errors}
                  />
                  {watchImage && (
                    <>
                      <img height={30} alt={watchImage} src={`${uploadFileUrl}/${watchImage}`} />
                    </>
                  )}
                </CardBody>
              </Card>

              {!!idFontFamily && (
                <>
                  <FontFaceList fontFamily={defaultData} />
                </>
              )}
            </Col>
            <Col sm={5}>
              <Card>
                <CardHeader>
                  <h5>설정</h5>
                </CardHeader>
                <CardBody>
                  <TbInputTag control={control} label="검색 태그" name={'tagList'} errors={errors} />

                  <FormGroup>
                    <Label>검색 필터</Label>
                    <div className={'d-flex flex-wrap'}>
                      <TbCheckbox
                        type={'checkbox'}
                        label={'손글씨'}
                        name={'handwriting'}
                        horizontal={true}
                        register={register}
                        errors={errors}
                      />
                      <TbCheckbox
                        type={'checkbox'}
                        label={'필기체'}
                        name={'cursive'}
                        horizontal={true}
                        register={register}
                        errors={errors}
                      />
                      <TbCheckbox
                        type={'checkbox'}
                        label={'세리프'}
                        name={'serif'}
                        horizontal={true}
                        register={register}
                        errors={errors}
                      />
                      <TbCheckbox
                        type={'checkbox'}
                        label={'고딕'}
                        name={'gothic'}
                        horizontal={true}
                        register={register}
                        errors={errors}
                      />
                      <TbCheckbox
                        type={'checkbox'}
                        label={'라운드'}
                        name={'round'}
                        horizontal={true}
                        register={register}
                        errors={errors}
                      />
                      <TbCheckbox
                        type={'checkbox'}
                        label={'디자인'}
                        name={'design'}
                        horizontal={true}
                        register={register}
                        errors={errors}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label>용도</Label>
                    <div className={'d-flex flex-wrap'}>
                      <TbCheckbox
                        type={'checkbox'}
                        label={'제목 서체 지원'}
                        name={'headerFont'}
                        horizontal={true}
                        register={register}
                        errors={errors}
                      />
                      <TbCheckbox
                        type={'checkbox'}
                        label={'본문 서체 지원'}
                        name={'bodyFont'}
                        horizontal={true}
                        register={register}
                        errors={errors}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      지원 언어<span className={'text-danger'}>(필수)</span>
                    </Label>
                    <div className={'d-flex flex-wrap'}>
                      {supportLanguages?.map((item: any) => (
                        <TbCheckbox
                          key={item.value}
                          label={item.label}
                          type={'checkbox'}
                          name={'languageList'}
                          value={item.value}
                          horizontal={true}
                          validation={{ required: true }}
                          register={register}
                          errors={errors}
                        />
                      ))}
                    </div>
                  </FormGroup>
                  <hr className={'my-4'} />
                  <TbRadioBoolean control={control} label={'상단 고정'} name="top" register={register} />
                  <TbRadioBoolean
                    control={control}
                    message={'비활성일 경우 콘텐츠가 노출되지 않습니다.'}
                    label={'게시 상태'}
                    name="active"
                    register={register}
                  />
                  {idFontFamily && (
                    <>
                      <TbDateText label={'생성일'} date={defaultData?.createdDate} />
                      <TbDateText label={'수정일'} date={defaultData?.updatedDate} />
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </Fragment>
  );
};

export default React.memo(FontFamilyUpdate);
