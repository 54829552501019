import APIClient from './APIClient';
import { PageInfo } from './types';

export type FontFamilyLanguage = 'en_US' | 'ko_KR';

export interface ReqFontFamilyPage extends PageInfo {
  language?: FontFamilyLanguage | '';
  recommend?: boolean | null;
  handwriting?: boolean | null;
  cursive?: boolean | null;
  serif?: boolean | null;
  gothic?: boolean | null;
  round?: boolean | null;
  design?: boolean | null;
  headerFont?: boolean | null;
  bodyFont?: boolean | null;
  active?: boolean | null;
}

export interface ReqFontFamily {
  name?: string;
  displayName?: string;
  fileName?: string;
  filePath?: string;
  image?: string;
  // css file
  css?: string;
  cssPath?: string;
  // options
  languageList?: FontFamilyLanguage[];
  recommend?: boolean | null;
  handwriting?: boolean | null;
  cursive?: boolean | null;
  serif?: boolean | null;
  gothic?: boolean | null;
  round?: boolean | null;
  design?: boolean | null;
  headerFont?: boolean | null;
  bodyFont?: boolean | null;
  active?: boolean | null;
  top?: boolean | null;
  tagList?: string[];
}

export interface ReqFontFace {
  weight?: string;
  weightName?: string;
  image?: string;
  otf?: string;
  ttf?: string;
  woff?: string;
  woff2?: string;
  active?: boolean | null;
  idFontFamily?: number;
}

export interface ResFontFace {
  idFontFace?: number;
  idFontFamily?: number;
  weight?: string;
  weightValue?: string;
  weightName?: string;
  image?: string;
  otf?: string;
  ttf?: string;
  woff?: string;
  woff2?: string;
  fontFamily?: string;
  active?: boolean;
  createdDate?: string;
  updatedDate?: string;
}

/**
 * [FontFamily] 페이징
 * */
export const getFontFamilyPage = async (params: ReqFontFamilyPage) => {
  const response = await APIClient.get('/v2/api/font-family/page', {
    params,
  });
  return response.data;
};

/**
 * [FontFamily] 단일 조회
 * */
export const getFontFamily = async (idFontFamily: number) => {
  const response = await APIClient.get(`/v2/api/font-family/${idFontFamily}`);
  return response.data;
};

/**
 * [FontFamily] 생성
 * */
export const createFontFamily = async (fontFamily: ReqFontFamily) => {
  const response = await APIClient.post('/v2/api/font-family', fontFamily);
  return response.data;
};

/**
 * [FontFamily] 수정
 * */
export const updateFontFamily = async ({ idFontFamily, body }: { idFontFamily: number; body: ReqFontFamily }) => {
  const response = await APIClient.patch(`/v2/api/font-family/${idFontFamily}`, body);
  return response.data;
};

/**
 * [FontFamily] 삭제
 * */
export const deleteFontFamily = async (idFontFamily: number) => {
  const response = await APIClient.delete(`/v2/api/font-family/${idFontFamily}`);
  return response.data;
};

/**
 * [FontFamily] 폰트 패밀리 Enum 목록
 * */
export const getFontFamilyEnum = async () => {
  const response = await APIClient.get('/v2/api/font-family/enum');
  return response.data;
};

/**
 * [FontFace] 리스트
 * */
export const getFontFaceList = async (params: { idFontFamily: number }) => {
  const response = await APIClient.get('/v2/api/font-face/list', {
    params,
  });
  return response.data;
};

/**
 * [FontFace] 단일 조회
 * */
export const getFontFace = async (idFontFace: number) => {
  const response = await APIClient.get(`/v2/api/font-face/${idFontFace}`);
  return response.data;
};

/**
 * [FontFace] 생성
 * */
export const createFontFace = async (fontFace: ReqFontFace) => {
  const response = await APIClient.post('/v2/api/font-face', fontFace);
  return response.data;
};

/**
 * [FontFace] 수정
 * */
export const updateFontFace = async ({ idFontFace, body }: { idFontFace: number; body: ReqFontFace }) => {
  const response = await APIClient.patch(`/v2/api/font-face/${idFontFace}`, body);
  return response.data;
};

/**
 * [FontFace] 삭제
 * */
export const deleteFontFace = async (idFontFace: number) => {
  const response = await APIClient.delete(`/v2/api/font-face/${idFontFace}`);
  return response.data;
};
