import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import useApiError from '../../hooks/useApiError';
import useDefaultFilter from '../../hooks/useDefaultFilter';
import { getProductPage, ReqProductList, ResProductList } from '../../api/productAPI';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import { toURLSearchParams } from '../../utils/urlUtils';
import { useQuery } from 'react-query';
import TbFilter from '../../components/common/ui/TbFilter';
import { Col, Row } from 'reactstrap';
import TbSelect from '../../components/common/ui/form/TbSelect';
import DataTable from 'react-data-table-component';
import { cellsStyle, headCellsStyle, rowsStyle } from '../../data/customizer/react-data-table-config';
import { ProductColumns } from './data/product-columns';

const ProductPage = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [defaultFilter] = useDefaultFilter();
  const [productList, setProductList] = useState<ResProductList[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const activeOption = [
    { label: '활성(지원)', value: 'true' },
    { label: '비활성(미지원)', value: 'false' },
  ];
  // query string 값으로 request 파라미터 초기화.
  const [reqParams, setReqParams] = useState<ReqProductList>({
    ...defaultFilter,
    // Custom Filter
    active: searchParams.get('active') === 'true' ? true : searchParams.get('active') === 'false' ? false : undefined,
  });

  // reqParam 변경되는 경우, url의 쿼리스트링 업데이트
  useDidMountEffect(() => {
    // `null` 또는 `undefined` 제거하고 모든 값을 스트링으로 변환.
    setSearchParams(toURLSearchParams(reqParams));
  }, [reqParams, setSearchParams]);

  /**
   * 목록 조회.
   * */
  const { isLoading } = useQuery(['getProductPage', { reqParams }], () => getProductPage(reqParams), {
    onSuccess: (data) => {
      setProductList(data?._embedded?.products || []);
      setTotalRows(data?.page?.totalElements || 0);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 페이지 변경 핸들러
   * */
  const handlePageChange = (page: number) => {
    setReqParams({
      ...reqParams,
      page: page - 1,
    });
  };

  /**
   * 한 페이지 당 보여줄 행 수 변경 핸들러
   * */
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParams({
      ...reqParams,
      size: newPerPage,
      page: page - 1,
    });
  };

  /**
   * 행 클릭 핸들러
   * */
  const handleRowClicked = (row: any) => {
    navigate(`/admin/product/update/${row.idProduct}`);
  };

  /**
   * 검색 핸들러
   * */
  const onSubmitSearch = (filters: any) => {
    setReqParams({
      ...reqParams,
      ...filters,
    });
  };

  return (
    <Fragment>
      <Breadcrumb parent="요금제 & 상품" title="상품 목록">
        <Link className="btn btn-primary" to={`/admin/product/create`}>
          새로 등록
        </Link>
      </Breadcrumb>
      <div className="container-fluid">
        {/* :::::::::: Filter : Start ::::::::::  */}
        <TbFilter onSubmit={onSubmitSearch} defaultValues={reqParams}>
          {({ register, errors, setValue }) => {
            setValue('active', reqParams.active);
            return (
              <Row>
                <Col md={6}>
                  <Row>
                    <Col sm={2}>
                      <label className="col-form-label">활성상태</label>
                    </Col>
                    <Col sm={10}>
                      <TbSelect
                        placeholder={'전체'}
                        name="active"
                        options={activeOption}
                        register={register}
                        errors={errors}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          }}
        </TbFilter>
        {/* :::::::::: Filter : End ::::::::::  */}

        {/*  :::::::::: List ::::::::::  */}
        <DataTable
          noHeader
          columns={ProductColumns}
          data={productList}
          progressPending={isLoading}
          highlightOnHover
          pointerOnHover
          pagination
          paginationServer
          paginationDefaultPage={(reqParams.page || 0) + 1}
          paginationTotalRows={totalRows}
          paginationPerPage={reqParams.size}
          paginationRowsPerPageOptions={[5, 10, 30, 50, 100]}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onRowClicked={handleRowClicked}
          dense={true}
          customStyles={{
            rows: rowsStyle,
            headCells: headCellsStyle,
            cells: cellsStyle,
          }}
        />
      </div>
    </Fragment>
  );
};
export default React.memo(ProductPage);
