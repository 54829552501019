import React, { Fragment, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Button, Card, CardBody, Col, Container, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  deleteCategoryIndustry,
  getCategoryIndustry,
  patchCategoryIndustry,
  postCategoryIndustry,
} from '../../../api/industryAPI';
import useApiError from '../../../hooks/useApiError';
import TbInput from '../../../components/common/ui/form/TbInput';
import TbModal from '../../../components/common/ui/TbModal';
import TbLoading from '../../../components/common/ui/TbLoading';
import Breadcrumb from '../../../components/common/breadcrumb';
import TbLangTab from '../../../components/common/ui/TbLangTab';
import TbTextarea from '../../../components/common/ui/form/TbTextarea';
import TbRadioBoolean from '../../../components/common/ui/form/TbRadioBoolean';

interface CategoryFormData {
  nameKoKr?: string;
  nameEnUs?: string;
  nameZhCn?: string;
  nameZhTw?: string;
  nameJaJp?: string;
  keywords?: string;
  koKr?: string;
  enUs?: string;
  zhCn?: string;
  zhTw?: string;
  jaJp?: string;
  active?: string;
}

const CreateWorkflow: React.FC = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const deleteMutation = useMutation(deleteCategoryIndustry);
  const createCategoryIndustryMutation = useMutation(postCategoryIndustry);
  const patchCategoryMutation = useMutation(({ id, categoryData }: { id: string; categoryData: any }) =>
    patchCategoryIndustry(id, categoryData),
  );
  const { id: idCategory } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Date states
  // Form
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CategoryFormData>({
    defaultValues: {
      active: 'true', // 여기에 초기값 설정
      koKr: 'true',
      enUs: 'true',
      zhCn: 'true',
      zhTw: 'true',
      jaJp: 'true',
    },
  });

  // 초기 데이터 로드
  const { data: defaultData } = useQuery(
    ['getCategoryIndustry', idCategory],
    () => getCategoryIndustry(idCategory as string),
    {
      enabled: !!idCategory,
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  useEffect(() => {
    if (defaultData) {
      console.debug('::: 갱신', defaultData);
      // 클라이언트
      setValue('nameKoKr', defaultData.nameKoKr);
      setValue('nameEnUs', defaultData.nameEnUs);
      setValue('nameZhCn', defaultData.nameZhCn);
      setValue('nameZhTw', defaultData.nameZhTw);
      setValue('nameJaJp', defaultData.nameJaJp);
      setValue('koKr', String(defaultData.internationalResBody ? defaultData.internationalResBody?.koKr : true));
      setValue('enUs', String(defaultData.internationalResBody ? defaultData.internationalResBody?.enUs : false));
      setValue('zhCn', String(defaultData.internationalResBody ? defaultData.internationalResBody?.zhCn : false));
      setValue('zhTw', String(defaultData.internationalResBody ? defaultData.internationalResBody?.zhTw : false));
      setValue('jaJp', String(defaultData.internationalResBody ? defaultData.internationalResBody?.jaJp : false));
      setValue('keywords', defaultData.keywords);
      setValue('active', String(defaultData.active));
    }
  }, [defaultData]);

  // 최종 Form Submit
  const onSubmit = async (data: any) => {
    setIsLoading(true);
    console.debug('data:::::', data);
    const requestData = {
      nameKoKr: data.nameKoKr,
      nameEnUs: data.nameEnUs,
      nameZhCn: data.nameZhCn,
      nameZhTw: data.nameZhTw,
      nameJaJp: data.nameJaJp,
      keywords: data.keywords,
      internationalReqBody: {
        koKr: data.koKr === 'true',
        enUs: data.enUs === 'true',
        zhCn: data.zhCn === 'true',
        zhTw: data.zhTw === 'true',
        jaJp: data.jaJp === 'true',
      },
      active: data.active === 'true',
    };
    if (idCategory && Number(idCategory) > 0) {
      // Update (PATCH)
      try {
        await patchCategoryMutation.mutateAsync({
          id: String(idCategory), // `id`를 문자열로 변환
          categoryData: requestData, // 업데이트할 데이터
        });
        navigate(-1);
      } catch (error) {
        handleApiError(error);
      }
    } else {
      // Create (POST)
      try {
        await createCategoryIndustryMutation.mutateAsync(requestData);
        navigate(-1);
      } catch (error) {
        handleApiError(error);
      }
    }

    setIsLoading(false);
  };

  // 삭제 핸들러
  const handleDelete = async () => {
    if (idCategory) {
      try {
        await deleteMutation.mutateAsync(idCategory);
        navigate(-1);
        toggleModal();
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  const toggleModal = () => {
    setIsDeleteModal(!isDeleteModal);
  };
  //  =================== 공통 항목 끝 ================== //

  return (
    <Fragment>
      <TbModal isOpen={isDeleteModal} onClickConfirm={handleDelete} toggle={toggleModal}>
        <h5 className="text-center m-0">삭제 하시겠습니까?</h5>
      </TbModal>
      <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
        <Breadcrumb parent="산업군 관리" title={`산업군 카테고리 ${idCategory ? '수정' : '생성'}`}>
          <div className="mb-0">
            {idCategory ? (
              <Button onClick={toggleModal} color="danger" className="me-3" type={'button'}>
                삭제
              </Button>
            ) : (
              ''
            )}
            <Button color="secondary" className="me-3" type="submit">
              {isLoading ? <TbLoading /> : idCategory ? '수정' : '생성'}
            </Button>
            <Button onClick={() => navigate(-1)} color="primary">
              목록
            </Button>
          </div>
        </Breadcrumb>
        <Container fluid={true}>
          <Row>
            <Col sm="8">
              <TbLangTab title={'산업군 카테고리 설정'} errors={errors}>
                <div>
                  <TbInput
                    label="카테고리 이름"
                    name={'nameKoKr'}
                    type="text"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbRadioBoolean control={control} label={'언어 활성 여부'} name={'koKr'} register={register} />
                </div>
                <div>
                  <TbInput
                    label="카테고리 이름"
                    name={'nameEnUs'}
                    type="text"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbRadioBoolean control={control} label={'언어 활성 여부'} name="enUs" register={register} />
                </div>
                <div>
                  <TbInput
                    label="카테고리 이름"
                    name={'nameZhCn'}
                    type="text"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbRadioBoolean control={control} label={'언어 활성 여부'} name="zhCn" register={register} />
                </div>
                <div>
                  <TbInput
                    label="카테고리 이름"
                    name={'nameZhTw'}
                    type="text"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbRadioBoolean control={control} label={'언어 활성 여부'} name="zhTw" register={register} />
                </div>
                <div>
                  <TbInput
                    label="카테고리 이름"
                    name={'nameJaJp'}
                    type="text"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbRadioBoolean control={control} label={'언어 활성 여부'} name="jaJp" register={register} />
                </div>
              </TbLangTab>
            </Col>
            <Col sm="4">
              <Card>
                <div className="card-header">
                  <h5>설정</h5>
                </div>
                <CardBody>
                  <TbTextarea
                    label="키워드"
                    id="memo"
                    name={'keywords'}
                    placeholder="검색 키워드를 작성해 주세요. ex) 패션, 옷,"
                    rows={5}
                    register={register}
                    errors={errors}
                  />
                  <TbRadioBoolean control={control} label={'게시 상태'} name="active" register={register} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Form>
    </Fragment>
  );
};
export default CreateWorkflow;
