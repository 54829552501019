import React from 'react';
import { Settings } from 'react-feather';
import { Link } from 'react-router-dom';
import {
  AinChavez,
  EricaHughes,
  FRIENDLIST,
  GingerJohnston,
  HileriJecno,
  KoriThomas,
  Online,
  PrasanthAnand,
  VincentPorter,
} from '../../constant';

const RightSidebar = () => {
  const userImage = 'https://placehold.co/50x50';
  return (
    <div>
      <div className="right-sidebar" id="right_side_bar">
        <div className="container p-0">
          <div className="modal-header p-l-20 p-r-20">
            <div className="col-sm-8 p-0">
              <h6 className="modal-title font-weight-bold">{FRIENDLIST}</h6>
            </div>
            <div className="col-sm-4 text-end p-0">
              <Settings />
              <i className="me-2" data-feather="settings"></i>
            </div>
          </div>
        </div>
        <div className="friend-list-search mt-0">
          <input type="text" placeholder="search friend" />
          <i className="fa fa-search"></i>
        </div>
        <div className="chat-box custom-scrollbar">
          <div className="people-list friend-list">
            <ul className="list">
              <li className="clearfix">
                <Link to={`${process.env.PUBLIC_URL}/chat-app/chat`}>
                  <img className="rounded-circle user-image" src={userImage} alt="" />
                  <div className="status-circle online"></div>
                  <div className="about">
                    <div className="name">{VincentPorter}</div>
                    <div className="status"> {Online}</div>
                  </div>
                </Link>
              </li>
              <li className="clearfix">
                <Link to={`${process.env.PUBLIC_URL}/chat-app/chat`}>
                  <img className="rounded-circle user-image" src={userImage} alt="" />
                  <div className="status-circle away"></div>
                  <div className="about">
                    <div className="name">{AinChavez}</div>
                    <div className="status"> {'28 minutes ago'}</div>
                  </div>
                </Link>
              </li>
              <li className="clearfix">
                <Link to={`${process.env.PUBLIC_URL}/chat-app/chat`}>
                  <img className="rounded-circle user-image" src={userImage} alt="" />
                  <div className="status-circle online"></div>
                  <div className="about">
                    <div className="name">{KoriThomas}</div>
                    <div className="status"> {Online}</div>
                  </div>
                </Link>
              </li>
              <li className="clearfix">
                <Link to={`${process.env.PUBLIC_URL}/chat-app/chat`}>
                  <img className="rounded-circle user-image" src={userImage} alt="" />
                  <div className="status-circle online"></div>
                  <div className="about">
                    <div className="name">{EricaHughes}</div>
                    <div className="status"> {Online}</div>
                  </div>
                </Link>
              </li>
              <li className="clearfix">
                <Link to={`${process.env.PUBLIC_URL}/chat-app/chat`}>
                  <img className="rounded-circle user-image" src={userImage} alt="" />
                  <div className="status-circle offline"></div>
                  <div className="about">
                    <div className="name">{GingerJohnston}</div>
                    <div className="status"> {'2 minutes ago'}</div>
                  </div>
                </Link>
              </li>
              <li className="clearfix">
                <Link to={`${process.env.PUBLIC_URL}/chat-app/chat`}>
                  <img className="rounded-circle user-image" src={userImage} alt="" />
                  <div className="status-circle away"></div>
                  <div className="about">
                    <div className="name">{PrasanthAnand}</div>
                    <div className="status"> {'2 hour ago'}</div>
                  </div>
                </Link>
              </li>
              <li className="clearfix">
                <Link to={`${process.env.PUBLIC_URL}/chat-app/chat`}>
                  <img className="rounded-circle user-image" src={userImage} alt="" />
                  <div className="status-circle online"></div>
                  <div className="about">
                    <div className="name">{HileriJecno}</div>
                    <div className="status"> {Online}</div>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightSidebar;
