import APIClient from './APIClient';
import { PageInfo } from './types';
import { ReqPlanList } from './planAPI';

export interface ReqCreditPlan {
  title?: string;
  credit?: number;
  discountRate?: number;
  active?: boolean;
  orderAscending?: number;
}
export interface ResCreditPlan {
  id?: number;
  title?: string;
  credit?: number;
  price?: number;
  discountRate?: number;
  active?: boolean;
  orderAscending?: number;
  createdDate?: string;
  updatedDate?: string;
}

export interface ReqCreditPlanList extends PageInfo {
  active?: boolean;
}

export const getCreditPlanList = async (params: ReqCreditPlanList) => {
  const response = await APIClient.get(`/v2/api/credit-plan/list`, {
    params,
  });
  return response.data;
};

/**
 * [Plan] 페이징
 * */
export const getCreditPlanPage = async (params: ReqPlanList) => {
  const response = await APIClient.get('/v2/api/credit-plan/page', {
    params,
  });
  return response.data;
};

/**
 * [CreditPlan] 단일 조회
 * */
export const getCreditPlan = async (idCreditPlan: number) => {
  const response = await APIClient.get(`/v2/api/credit-plan/${idCreditPlan}`);
  return response.data;
};

/**
 * [CreditPlan] 생성
 * */
export const createCreditPlan = async (creditPlan: ReqCreditPlan) => {
  const response = await APIClient.post('/v2/api/credit-plan', creditPlan);
  return response.data;
};

/**
 * [CreditPlan] 수정
 * */
export const updateCreditPlan = async ({ idCreditPlan, body }: { idCreditPlan: number; body: ReqCreditPlan }) => {
  const response = await APIClient.patch(`/v2/api/credit-plan/${idCreditPlan}`, body);
  return response.data;
};

/**
 * [CreditPlan] 삭제
 * */
export const deleteCreditPlan = async (idCreditPlan: number) => {
  const response = await APIClient.delete(`/v2/api/credit-plan/${idCreditPlan}`);
  return response.data;
};
