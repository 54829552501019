import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import useApiError from '../../hooks/useApiError';
import Breadcrumb from '../../components/common/breadcrumb';
import TbFilter from '../../components/common/ui/TbFilter';
import { Col, Row } from 'reactstrap';
import TbSelect from '../../components/common/ui/form/TbSelect';
import DataTable from 'react-data-table-component';
import { getSubscriptionPage, ReqSubscriptionPage } from '../../api/subscriptionAPI';
import { subscriptionColumns } from './data/subscription-columns';
import { cellsStyle, headCellsStyle, rowsStyle } from '../../data/customizer/react-data-table-config';
import useDefaultFilter from '../../hooks/useDefaultFilter';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import { toURLSearchParams } from '../../utils/urlUtils';

const typeList: any[] = [
  { label: '무료 계정', value: 'FREE' },
  { label: '유료 계정', value: 'PAID' },
  { label: '관리자 계정', value: 'ADMIN' },
];

const statusList: any[] = [
  { label: '구독중', value: 'SUBSCRIBE' },
  { label: '구독 만료', value: 'EXPIRED' },
];

const periodTypeList: any[] = [
  { label: '월간', value: 'MONTHLY' },
  { label: '연간', value: 'YEARLY' },
];

function SubscriptionList() {
  const handleApiError = useApiError(); // 에러 처리 훅
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [defaultFilter] = useDefaultFilter();

  // Pagination
  const [listData, setListData] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [reqParams, setReqParams] = useState<ReqSubscriptionPage>({
    ...defaultFilter,
    // Custom Filter
    type: (searchParams.get('type') as 'FREE' | 'PAID' | 'ADMIN') || '',
    status: (searchParams.get('status') as 'SUBSCRIBE' | 'EXPIRED') || '',
    periodType: (searchParams.get('periodType') as 'MONTHLY' | 'YEARLY') || '',
  });

  // reqParam 변경되는 경우, url의 쿼리스트링 업데이트
  useDidMountEffect(() => {
    // `null` 또는 `undefined` 제거하고 모든 값을 스트링으로 변환.
    setSearchParams(toURLSearchParams(reqParams));
  }, [reqParams, setSearchParams]);

  /**
   * 페이지 데이터 요청.
   * */
  const { isLoading } = useQuery(['getSubscriptionPage', { reqParams }], () => getSubscriptionPage({ ...reqParams }), {
    onSuccess: (data) => {
      setListData(data?._embedded?.subscriptions || []);
      setTotalRows(data?.page?.totalElements | 0);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 페이지 변경 핸들러
   * */
  const handlePageChange = (page: number) => {
    setReqParams({
      ...reqParams,
      page: page - 1,
    });
  };

  /**
   * 페이지 사이즈 변경
   * */
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParams({
      ...reqParams,
      size: newPerPage,
      page: page - 1,
    });
  };

  // 검색 핸들러
  const onSubmitSearch = (filters: any) => {
    setReqParams({
      ...reqParams,
      ...filters,
    });
  };

  // 행 클릭 핸들러
  const handleRowClicked = (row: any) => {
    navigate(`/admin/subscription/read/${row.id}`);
  };

  return (
    <div>
      <Breadcrumb parent="회원 구독" title="회원 구독 목록"></Breadcrumb>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <TbFilter onSubmit={onSubmitSearch} defaultValues={reqParams}>
              {({ register, errors, setValue }) => {
                setValue('type', reqParams.type);
                setValue('status', reqParams.status);
                setValue('periodType', reqParams.periodType);
                return (
                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col sm={2}>
                          <label className="col-form-label">구독 상태</label>
                        </Col>
                        <Col sm={10}>
                          <TbSelect
                            placeholder={'전체'}
                            name="status"
                            options={statusList ? statusList : []}
                            register={register}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={2}>
                          <label className="col-form-label">구독 타입</label>
                        </Col>
                        <Col sm={10}>
                          <TbSelect
                            placeholder={'전체'}
                            name="type"
                            options={typeList ? typeList : []}
                            register={register}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col sm={2}>
                          <label className="col-form-label">약정 주기</label>
                        </Col>
                        <Col sm={10}>
                          <TbSelect
                            placeholder={'전체'}
                            name="periodType"
                            options={periodTypeList ? periodTypeList : []}
                            register={register}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              }}
            </TbFilter>
          </div>
          <div className={'col-xl-12'}>
            <div className={'card-table-body'}>
              <DataTable
                noHeader
                columns={subscriptionColumns}
                data={listData}
                progressPending={isLoading}
                highlightOnHover
                pointerOnHover
                pagination
                paginationServer
                paginationDefaultPage={(reqParams.page || 0) + 1}
                paginationTotalRows={totalRows}
                paginationPerPage={reqParams.size}
                paginationRowsPerPageOptions={[5, 10, 30, 50, 100]}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onRowClicked={handleRowClicked}
                dense={true}
                customStyles={{
                  rows: rowsStyle,
                  headCells: headCellsStyle,
                  cells: cellsStyle,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(SubscriptionList);
