import React, { Fragment, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Button, Card, CardBody, Col, Container, Form, Row } from 'reactstrap';
import useApiError from '../../hooks/useApiError';
import Breadcrumb from '../../components/common/breadcrumb';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteTask, getTask, patchTask } from '../../api/taskAPI';
import TbInput from '../../components/common/ui/form/TbInput';
import { useForm } from 'react-hook-form';
import TbTextarea from '../../components/common/ui/form/TbTextarea';
import DatePicker from 'react-datepicker';
import ManageBrand from '../subscription/components/ManageBrand';
import TbModal from '../../components/common/ui/TbModal';
import { toast } from 'react-toastify';
import moment from 'moment';

interface TaskFormData {
  request?: string;
  status?: string;
  title?: string;
  credit?: number;
  finalCredit?: number;
  createdDate: string;
  startDate?: string;
  endDate?: string;
  decision?: string;
  note?: string;
}

// interface PropsType {
//   brand?: any;
// }

// const statusList: any[] = [
//   { label: '신청 완료', value: 'REQUEST' },
//   { label: '작업 진행중', value: 'PROCEEDING' },
//   { label: '작업 완료', value: 'COMPLETED' },
//   { label: '작업 취소', value: 'CANCELED' },
// ];

const TaskUpdate: React.FC = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const patchTaskMutation = useMutation(({ idTask, requestData }: { idTask: string; requestData: any }) =>
    patchTask(idTask, requestData),
  );
  const deleteMutation = useMutation(deleteTask);
  const navigate = useNavigate();
  // 크레딧 아이디
  const { id: idTask } = useParams<{ id: string }>();
  // Date states
  const [, setCreatedDate] = useState<Date | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [statusData, setStatusData] = useState('');
  // Modal
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  // 브랜드 인포 refetch
  const [isRefetchBrandInfo, setIsRefetchBrandInfo] = useState(false);

  // Date states
  // Form
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<TaskFormData>({});

  // 초기 데이터 로드
  const { data: defaultData, refetch } = useQuery(['getTask', idTask], () => getTask(idTask as string), {
    enabled: !!idTask,
    onError: (error) => {
      handleApiError(error);
    },
  });

  useEffect(() => {
    if (defaultData) {
      if (defaultData.status === 'PROCEEDING') {
        setIsRefetchBrandInfo(!isRefetchBrandInfo);
      }
      // 클라이언트
      setValue('request', defaultData.request);
      setValue('status', defaultData.status);
      setValue('title', defaultData.title);
      setValue('decision', defaultData.decision);
      setValue('note', defaultData.note);
      setValue('finalCredit', defaultData.finalCredit);
      setCreatedDate(new Date(defaultData.createdDate));
      setStartDate(defaultData.startDate ? new Date(defaultData.startDate) || new Date() : new Date());
      setEndDate(new Date(defaultData.endDate));
    }
  }, [defaultData]);

  const onSubmit = async (data: any) => {
    const requestData = {
      request: data.request,
      status: statusData,
      title: data.title,
      finalCredit: data.finalCredit,
      createdDate: data.createdDate,
      note: data.note,
      startDate: startDate,
      endDate: endDate,
      decision: data.decision,
    };
    // Update (PATCH)
    try {
      await patchTaskMutation.mutateAsync({
        idTask: String(idTask), // `id`를 문자열로 변환
        requestData, // 업데이트할 데이터
      });
      refetch();
      toast.success('변경 완료');
    } catch (error) {
      handleApiError(error);
    }
  };

  // 삭제 핸들러
  const handleDelete = async () => {
    if (defaultData) {
      try {
        await deleteMutation.mutateAsync(defaultData.id);
        navigate(-1);
        toggleModal();
        toast.success('삭제 완료');
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  const toggleModal = () => {
    setIsDeleteModal(!isDeleteModal);
  };

  //  숫자만 입력할 수 있도록 문자 입력 방지하는 onChange 핸들러
  const handleNumberInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    // 숫자만 입력할 수 있도록 필터링
    // 숫자만 남기고 모든 문자 제거
    e.target.value = value.replace(/[^0-9]/g, '');
  };

  return (
    <Fragment>
      <TbModal isOpen={isDeleteModal} onClickConfirm={handleDelete} toggle={toggleModal}>
        <h5 className="text-center m-0">삭제 하시겠습니까?</h5>
      </TbModal>
      <Breadcrumb parent="디자이너 작업" title="디자이너 작업 상세">
        <div className="mb-0">
          {defaultData?.status === 'REQUEST' && (
            <Button color="danger" onClick={toggleModal} className={'me-3'}>
              삭제
            </Button>
          )}
          <Button color="primary" onClick={() => navigate(-1)}>
            목록
          </Button>
        </div>
      </Breadcrumb>
      <Container fluid={true}>
        <Row>
          <Col sm="4">
            {/****** 브랜드/유저 정보 ******/}
            <ManageBrand idBrand={defaultData?.idBrand} refetchBrandInfo={isRefetchBrandInfo} />

            {/* ::::::::::::: 요청 내역 :::::::::::::: */}
            <Card>
              <div className="card-header">
                <h5>요청 내역</h5>
              </div>
              <CardBody>
                <Row>
                  <Col xs="6" className={'detail-item'}>
                    <div className={'m-r-30'}>신청 크레딧</div>
                    <span>{defaultData?.credit} Credit</span>
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    <div className={'m-r-30 mb-1'}>신청일</div>
                    <span>
                      {defaultData?.createdDate ? moment(defaultData?.createdDate).format('YYYY/MM/DD HH:mm:ss') : '-'}
                    </span>
                  </Col>
                  <Col xs="12">
                    <TbInput label="제목" name={'title'} disabled type="text" register={register} errors={errors} />
                  </Col>
                  <Col xs="12" className={'detail-item'}>
                    <TbTextarea
                      label="요청 내용"
                      id="request"
                      disabled
                      name={'request'}
                      placeholder="요청 내용이 없습니다."
                      rows={10}
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          {/* 작업 상세 */}
          <Col sm="8">
            <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
              <Card>
                <div className="card-header">
                  <h5>작업 상세</h5>
                </div>
                <CardBody>
                  <Row>
                    {/*   작업 상태  */}
                    <Col xs={6} className={'detail-item'}>
                      <div className={'m-r-30 mb-1'}>상태</div>
                      <div>
                        {defaultData?.status && (
                          <>
                            {defaultData.status === 'REQUEST' && (
                              <span className={'btn btn-xs btn-info text-white'}>신청 완료</span>
                            )}
                            {defaultData.status === 'PROCEEDING' && (
                              <span className={'btn btn-xs btn-success text-white'}>작업 진행중</span>
                            )}
                            {defaultData.status === 'COMPLETED' && (
                              <span className={'btn btn-xs btn-warning text-white'}>작업 완료</span>
                            )}
                            {defaultData.status === 'CANCELED' && (
                              <span className={'btn btn-xs btn-danger text-white'}>작업 취소</span>
                            )}
                          </>
                        )}
                      </div>
                    </Col>
                    <Col xs="6">
                      {defaultData?.status !== 'REQUEST' && (
                        <div>
                          <div className={'mb-1'}>차감 크레딧</div>
                          <div className={'f-w-700 h5'}>{defaultData?.finalCredit} Credit</div>
                        </div>
                      )}
                    </Col>

                    {defaultData?.status !== 'PROCEEDING ' && (
                      <Col xs={12} className={'mb-3'}>
                        <div>
                          <div className={'mb-1'}>시작 시간</div>
                          <DatePicker
                            className="datepicker-here form-control"
                            selected={startDate instanceof Date ? startDate : null} // Ensure selected is either a Date object or null
                            disabled={defaultData?.status !== 'REQUEST'}
                            showTimeSelect
                            dateFormat={'yyyy-MM-dd h:mm aa'}
                            onChange={(date: Date | null) => setStartDate(date)}
                          />
                        </div>
                      </Col>
                    )}
                    {defaultData?.status !== 'REQUEST' && (
                      <Col xs={12} className={'mb-3'}>
                        <div>
                          <div className={'mb-1'}>종료 시간</div>
                          <DatePicker
                            className="datepicker-here form-control"
                            selected={endDate}
                            disabled={defaultData?.status === 'COMPLETED' || defaultData?.status === 'CANCELED'}
                            showTimeSelect
                            dateFormat={'yyyy-MM-dd h:mm aa'}
                            onChange={(date: Date | null) => setEndDate(date)}
                          />
                        </div>
                      </Col>
                    )}
                    <Col xs="12">
                      <TbTextarea
                        label="확정 작업 내용"
                        id="decision"
                        name={'decision'}
                        placeholder="작업 내용을 작성해주세요."
                        disabled={defaultData?.status === 'COMPLETED' || defaultData?.status === 'CANCELED'}
                        rows={6}
                        register={register}
                        errors={errors}
                      />
                    </Col>
                    <Col xs="12">
                      <TbTextarea
                        label={
                          '관리자 메모<span class="text-primary">(클라이언트 화면에서는 노출되지 않습니다.)</span>'
                        }
                        id="note"
                        name="note"
                        placeholder="메모를 작성해주세요."
                        disabled={defaultData?.status === 'COMPLETED' || defaultData?.status === 'CANCELED'}
                        rows={6}
                        register={register}
                        errors={errors}
                      />
                    </Col>
                    <Col xs="12">
                      {defaultData?.status === 'REQUEST' && (
                        <TbInput
                          label={'차감 금액'}
                          name={'finalCredit'}
                          placeholder={'차감 금액'}
                          type="text"
                          register={register}
                          errors={errors}
                          validation={{ required: true }}
                          onChange={handleNumberInputChange} // // 문자 입력을 막는 onChange 핸들러
                        />
                      )}
                    </Col>
                  </Row>
                  {/*<Button color="primary" type={'submit'}>*/}
                  {/*  Test*/}
                  {/*</Button>*/}
                  {(defaultData?.status === 'REQUEST' || defaultData?.status === 'PROCEEDING') && (
                    <div>
                      <div className={'my-3'} style={{ margin: '0 -30px', borderBottom: '1px solid #e8ebf2' }}></div>
                      <div className={'d-flex justify-content-end'}>
                        {defaultData?.status !== 'COMPLETED' && (
                          <Button
                            color="danger"
                            onClick={() => {
                              setStatusData('CANCELED');
                            }}
                            type={'submit'}
                            className={'me-3'}
                          >
                            작업 취소
                          </Button>
                        )}
                        {defaultData?.status === 'REQUEST' && (
                          <Button
                            color="success"
                            onClick={() => {
                              setStatusData('PROCEEDING');
                            }}
                            type={'submit'}
                          >
                            작업 시작
                          </Button>
                        )}
                        {defaultData?.status === 'PROCEEDING' && (
                          <Button
                            color="warning"
                            onClick={() => {
                              setStatusData('COMPLETED');
                            }}
                            type={'submit'}
                          >
                            작업 완료
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default TaskUpdate;
