import React, { ReactElement, useEffect, useId, useMemo, useRef, useState } from 'react';
import { TBFormGroup, TBLabel } from '../../../styledComponents';
import styled, { css } from 'styled-components';
import { File, UploadCloud } from 'react-feather';
import { checkFileExtension, convertToBytes } from '../../../../../utils/fileUtils';
import { toast } from 'react-toastify';
import TbResourceFileUploadItem from './TbResourceFileUploadItem';
import { Controller } from 'react-hook-form';

interface DefaultProps {
  type: 'FILE' | 'IMAGE';
  path?: string;
  /** 파일명을 지정하고 싶은 경우 설정, 설정하지 않으면 랜덤값 자동 지정.*/
  fileName?: string;
  label?: string;
  message?: string | ReactElement;
  name?: string;
  accept?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onComplete?: (url: string) => void;
  onDeleteComplete?: (url: string) => void;
  /**
   * ture: string[]을 린턴한다.
   * false: string을 리턴한다.
   * */
  multiple?: boolean;
  maxCount?: number;
  maxSize?: number;
  horizontal?: boolean;
  initialData?: string | string[];
  initialFile?: File[];
  disabled?: boolean;
  control?: any;
  validation?: any;
  errors?: any;
}

export interface SelectedFile {
  file?: File;
  url: string;
  isUploaded: boolean;
}
/**
 * 업로드할 파일의 유효성을 검사하고 useForm과 연동을 관리한다.
 * */
const TbResourceFileUpload = ({
  type = 'FILE',
  path,
  fileName,
  label = '',
  message = '',
  name = 'file',
  accept = '',
  onComplete,
  onDeleteComplete,
  multiple = false,
  maxCount = 1,
  maxSize = 10, // 10MB
  horizontal = false,
  initialData = '',
  initialFile,
  disabled = false,
  control,
  validation = undefined,
  errors = {},
}: DefaultProps) => {
  const id = useId();
  const refFileInputInner = useRef<HTMLInputElement>(null);
  const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([]);

  // 초기 값 설정을 위한 useEffect
  // 배열인 경우와 배열이 아닌경우가 있음.
  useEffect(() => {
    if (initialData) {
      const dataArray = Array.isArray(initialData) ? initialData : [initialData];
      setSelectedFiles(
        dataArray.map((item) => ({
          file: undefined,
          url: item,
          isUploaded: true,
        })),
      );
    }
  }, [initialData]);

  // 초기 값 설정을 위한 useEffect
  // 배열인 경우와 배열이 아닌경우가 있음.
  useEffect(() => {
    if (initialFile && initialFile.length > 0) {
      setSelectedFiles(
        initialFile.map((file) => ({
          file: file,
          url: '',
          isUploaded: false,
        })),
      );
    }
  }, [initialFile]);

  // 멀티플 조건에 따른 최대 파일 수 계산.
  const calcMaxCount = useMemo(() => {
    return multiple ? maxCount : 1;
  }, [multiple, maxCount]);

  /**
   * 파일 변경 이벤트
   * - 유효성 검사.
   * */
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);

    // 최대 개수 제한 적용
    if (files.length + selectedFiles.length > calcMaxCount) {
      toast.warning(`최대 ${calcMaxCount}개의 파일만 업로드할 수 있습니다.`);
      return;
    }

    files.map((file) => {
      // 파일 확장자 확인
      if (accept && !checkFileExtension(file.name, accept?.split(','))) {
        toast.warning(`허용 되지 않는 확장자의 파일이 포함되어있습니다.\n(가능 확장자 : ${accept})`);
        return;
      }
      // 파일 크기 확인
      if (file.size > convertToBytes(maxSize, 'MB')) {
        toast.warning(`업로드 가능한 최대 파일 크기는 ${maxSize}MB 입니다.`);
        return;
      }
    });

    // 업로드 대상 배열.
    const targetFiles = files.map((file) => {
      return {
        file: file,
        url: '',
        isUploaded: false,
      } as SelectedFile;
    });
    setSelectedFiles([...selectedFiles, ...targetFiles]);

    // 인풋 리셋
    if (refFileInputInner.current) {
      (refFileInputInner.current as HTMLInputElement).value = '';
    }
  };

  return (
    <TBFormGroup horizontal={horizontal.toString()}>
      {!label ? null : (
        <TBLabel horizontal={horizontal.toString()}>
          {label}
          {validation?.required && <span className={'text-danger'}>(필수)</span>}
        </TBLabel>
      )}
      <div className={'w-100'}>
        {/* Upload File Input */}
        <Controller
          control={control}
          name={name}
          rules={validation}
          render={({ field }) => (
            <>
              <FileInput
                ref={refFileInputInner}
                id={`uploadInputFile${id}`}
                type={'file'}
                accept={accept}
                multiple={multiple}
                disabled={disabled}
                onChange={handleFileChange}
              />
              {/* Thumbnail UI*/}
              {message && <div className={'mt-1 mb-2'}>{message}</div>}

              <div className={'d-flex flex-wrap w-100'}>
                {selectedFiles.map((fileInfo, originIndex) => (
                  <TbResourceFileUploadItem
                    type={type}
                    path={path}
                    fileName={fileName}
                    key={fileInfo.file?.name || fileInfo.url}
                    file={fileInfo.file}
                    isUploaded={fileInfo.isUploaded}
                    url={fileInfo.url}
                    onComplete={(url) => {
                      const updatedFiles = selectedFiles.map((fileInfo, index) =>
                        originIndex === index ? { ...fileInfo, url, isUploaded: true } : fileInfo,
                      );
                      setSelectedFiles(updatedFiles);
                      // useForm에 적용
                      const urls = updatedFiles.map((fileInfo) => fileInfo.url);
                      field.onChange(multiple ? urls : url);
                      onComplete?.(url);
                    }}
                    onDeleteComplete={() => {
                      const updatedFiles = selectedFiles.filter((_, index) => index !== originIndex);
                      setSelectedFiles(updatedFiles);

                      // useForm에 적용
                      const urls = updatedFiles.map((fileInfo) => fileInfo.url);
                      field.onChange(multiple ? urls : urls[0] || '');
                      onDeleteComplete?.(urls[0]);
                    }}
                  />
                ))}

                {/* 업로드 버튼 */}
                {selectedFiles.length < calcMaxCount && (
                  <ThumbWrap htmlFor={`uploadInputFile${id}`} type={type}>
                    <UploadCloud size={30} color={'#007bff'} style={{ margin: 10 }} />
                    <h6 className={'mb-0'}>Open Finder</h6>
                  </ThumbWrap>
                )}
              </div>
            </>
          )}
        />

        {errors && errors[name] && (
          <div className="text-danger mt-1">{errors[name].message || `${label}을/를 업로드하세요.`}</div>
        )}
      </div>
    </TBFormGroup>
  );
};

export default React.memo(TbResourceFileUpload);

const FileInput = styled.input`
  position: absolute;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  z-index: -999;
  overflow: hidden;
`;
const ThumbWrap = styled.label<{ type: 'FILE' | 'IMAGE' }>`
  ${(props) =>
    props.type === 'IMAGE' &&
    css`
      width: 120px;
      height: 120px;
      flex-direction: column;
    `};

  ${(props) =>
    props.type === 'FILE' &&
    css`
      width: 250px;
      height: 50px;
    `};

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px dashed #dee2e6;
  text-align: center;
  &.hover {
    border-color: #007bff;
  }
`;
