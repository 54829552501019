import React from 'react';

export const ProductColumns = [
  {
    name: 'id',
    selector: (row: any) => row.idProduct,
    center: true,
    width: '60px',
  },
  {
    name: '이름',
    selector: (row: any) => row.name,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: '원가',
    cell: (row: any) => {
      return (
        <span>
          <b>
            {row.currency} {row.cost.toLocaleString()}
          </b>
        </span>
      );
    },
    sortable: true,
    center: true,
    wrap: true,
    width: '130px',
  },
  {
    name: '판매가',
    cell: (row: any) => {
      return (
        <span>
          <b>
            {row.currency} {row.price.toLocaleString()}
          </b>
        </span>
      );
    },
    sortable: true,
    center: true,
    wrap: true,
    width: '130px',
  },
  {
    name: '할인율',
    selector: (row: any) => row.discountRate * 100 + '%',
    sortable: true,
    center: true,
    wrap: true,
    width: '80px',
  },
  {
    name: '국가',
    selector: (row: any) => row.locale,
    sortable: true,
    center: true,
    wrap: true,
    width: '80px',
  },
  {
    name: '활성 여부',
    selector: (row: any) => row.active,
    cell: (row: any) => {
      return row.active ? (
        <span className="badge badge-success">활성</span>
      ) : (
        <span className="badge badge-danger">비활성</span>
      );
    },
    sortable: true,
    center: true,
    wrap: true,
    width: '100px',
  },
];
