import React, { Fragment, useState } from 'react';
import TbFilter from '../../components/common/ui/TbFilter';
import DataTable from 'react-data-table-component';
import useApiError from '../../hooks/useApiError';
import { getWorkflowEnum, getWorkflowPage, ReqWorkflowList } from '../../api/workflowAPI';
import { useQuery } from 'react-query';
import { workflowColumns } from './data/workflow-columns';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Breadcrumb from '../../components/common/breadcrumb';
import { Col, Row } from 'reactstrap';
import TbSelect from '../../components/common/ui/form/TbSelect';
import { cellsStyle, headCellsStyle, rowsStyle } from '../../data/customizer/react-data-table-config';
import { enumToSelect } from '../../utils/enumUtils';
import useDefaultFilter from '../../hooks/useDefaultFilter';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import { toURLSearchParams } from '../../utils/urlUtils';

const CardView = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [defaultFilter] = useDefaultFilter();
  const [pageInfoData, setPageInfoData] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  // state
  const [reqParams, setReqParams] = useState<ReqWorkflowList>({
    ...defaultFilter,
    // Custom Filter
    type: 'GENERAL',
    onBoarding: searchParams.get('onBoarding') as string,
    manager: searchParams.get('manager') as string,
    design: searchParams.get('design') as string,
    platform: searchParams.get('platform') as string,
    priority: searchParams.get('priority') as string,
  });

  // reqParam 변경되는 경우, url의 쿼리스트링 업데이트
  useDidMountEffect(() => {
    // `null` 또는 `undefined` 제거하고 모든 값을 스트링으로 변환.
    setSearchParams(toURLSearchParams(reqParams));
  }, [reqParams, setSearchParams]);

  /**
   * 목록 조회.
   * */
  const { isLoading } = useQuery(['getWorkflowPage', { reqParams }], () => getWorkflowPage(reqParams), {
    onSuccess: (data) => {
      setPageInfoData(data?._embedded?.workflowPageResBodies || []);
      setTotalRows(data?.page?.totalElements || 0);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  // 페이지 변경 핸들러
  const handlePageChange = (page: number) => {
    setReqParams({
      ...reqParams,
      page: page - 1,
    });
  };

  // 한 페이지 당 보여줄 행 수 변경 핸들러
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParams({
      ...reqParams,
      size: newPerPage,
      page: page - 1,
    });
  };

  // =================== 공통 항목 끝 ==================  //

  // Enum API
  const { data: enumType } = useQuery(['enumData'], getWorkflowEnum, {
    // enabled: !!platformList,
    select: (data) => ({
      typeList: enumToSelect(data.typeList),
      onBoardingList: enumToSelect(data.onBoardingList),
      designList: enumToSelect(data.designList),
      platformList: enumToSelect(data.platformList),
      managerList: enumToSelect(data.managerList),
      priorityList: enumToSelect(data.priorityList),
    }),
    onError: (error) => {
      handleApiError(error);
    },
  });

  // 검색 핸들러
  const onSubmitSearch = (filters: any) => {
    setReqParams({
      ...reqParams,
      ...filters,
    });
  };

  // 행 클릭 핸들러
  const handleRowClicked = (row: any) => {
    navigate(`/admin/workflow/read/${row.id}`);
  };

  return (
    <Fragment>
      <Breadcrumb parent="엔어프라이즈 고객" title="일반 고객 목록">
        <Link className="btn btn-primary" to={`/admin/workflow/update`}>
          새로 등록
        </Link>
      </Breadcrumb>
      <div className="container-fluid">
        <div className="row">
          {/* :::::::::: Filter : Start ::::::::::  */}
          <div className="col-xl-12">
            <TbFilter onSubmit={onSubmitSearch} defaultValues={reqParams}>
              {({ register, errors, setValue }) => {
                setValue('type', reqParams.type);
                setValue('onBoarding', reqParams.onBoarding);
                setValue('manager', reqParams.manager);
                setValue('design', reqParams.design);
                setValue('platform', reqParams.platform);
                setValue('priority', reqParams.priority);
                return (
                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col sm={2}>
                          <label className="col-form-label">타입</label>
                        </Col>
                        <Col sm={10}>
                          <TbSelect
                            placeholder={'전체'}
                            name="type"
                            options={enumType?.typeList ? enumType.typeList : []}
                            register={register}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col sm={2}>
                          <label className="col-form-label">온보딩 상태</label>
                        </Col>
                        <Col sm={10}>
                          <TbSelect
                            placeholder={'전체'}
                            name="onBoarding"
                            options={enumType?.onBoardingList ? enumType.onBoardingList : []}
                            register={register}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col sm={2}>
                          <label className="col-form-label">플랫폼</label>
                        </Col>
                        <Col sm={10}>
                          <TbSelect
                            placeholder={'전체'}
                            name="platform"
                            options={enumType?.platformList ? enumType.platformList : []}
                            register={register}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col sm={2}>
                          <label className="col-form-label">디자인 상태</label>
                        </Col>
                        <Col sm={10}>
                          <TbSelect
                            placeholder={'전체'}
                            name="design"
                            options={enumType?.designList ? enumType.designList : []}
                            register={register}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col md={6}>
                      <Row>
                        <Col sm={2}>
                          <label className="col-form-label">매니저</label>
                        </Col>
                        <Col sm={10}>
                          <TbSelect
                            placeholder={'전체'}
                            name="manager"
                            options={enumType?.managerList ? enumType.managerList : []}
                            register={register}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col sm={2}>
                          <label className="col-form-label">우선순위</label>
                        </Col>
                        <Col sm={10}>
                          <TbSelect
                            placeholder={'전체'}
                            name="priority"
                            options={enumType?.priorityList ? enumType.priorityList : []}
                            register={register}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              }}
            </TbFilter>
          </div>
          {/* :::::::::: Filter : End ::::::::::  */}

          {/*  :::::::::: List ::::::::::  */}
          <div className=" col-xl-12 mb-4">
            <DataTable
              noHeader
              columns={workflowColumns(enumType)}
              data={pageInfoData}
              progressPending={isLoading}
              highlightOnHover
              pointerOnHover
              pagination
              paginationServer
              paginationDefaultPage={(reqParams.page || 0) + 1}
              paginationTotalRows={totalRows}
              paginationPerPage={reqParams.size}
              paginationRowsPerPageOptions={[5, 10, 30, 50, 100]}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              onRowClicked={handleRowClicked}
              dense={true}
              customStyles={{
                rows: rowsStyle,
                headCells: headCellsStyle,
                cells: cellsStyle,
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CardView;
