import { useSearchParams } from 'react-router-dom';
import { PageInfo } from '../api/types';
import { useState } from 'react';
import moment from 'moment/moment';

const useDefaultFilter = () => {
  const [searchParams] = useSearchParams();

  const defaultFilter: PageInfo = {
    page: Number(searchParams.get('page')) || 0,
    size: Number(searchParams.get('size')) || 30,
    query: searchParams.get('query') || '',
    startDate: moment(searchParams.get('startDate')).isValid()
      ? moment(searchParams.get('startDate')).format('YYYY-MM-DD HH:mm:ss')
      : '',
    endDate: moment(searchParams.get('endDate')).isValid()
      ? moment(searchParams.get('endDate')).format('YYYY-MM-DD HH:mm:ss')
      : '',
    sort: searchParams.get('sort') || '',
  };

  return useState<PageInfo>(defaultFilter);
};
export default useDefaultFilter;
