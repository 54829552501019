// 카테고리
import React from 'react';

export const CategoryTaskColumns = [
  {
    name: 'id',
    selector: (row: any) => row.id,
    // sortable: true,
    center: true,
    // wrap: true,
    width: '60px',
  },
  {
    name: '카테고리',
    selector: (row: any) => row.name,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: '내용',
    selector: (row: any) => row.content,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: '활성 여부',
    selector: (row: any) => row.active,
    cell: (row: any) => {
      return row.active ? (
        <span className="badge badge-success">활성</span>
      ) : (
        <span className="badge badge-danger">비활성</span>
      );
    },
    sortable: true,
    center: true,
    wrap: true,
  },
];

// 작품
export const TaskColumns = [
  {
    name: 'id',
    selector: (row: any) => row.id,
    // sortable: true,
    center: true,
    // wrap: true,
    width: '60px',
  },
  {
    name: '제목',
    selector: (row: any) => row.content,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: '크레딧',
    selector: (row: any) => row.credit,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: '원화',
    selector: (row: any) => row.priceRange,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: '상품 등금',
    selector: (row: any) => row.level,
    sortable: true,
    center: true,
    wrap: true,
  },
];
