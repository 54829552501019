import React, { useState } from 'react';
import { useQuery } from 'react-query';
import useApiError from '../../../hooks/useApiError';
import { getTaskPage, ReqTaskPage } from '../../../api/taskAPI';
import DataTable from 'react-data-table-component';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { taskColumns } from '../data/task-columns';
import { useNavigate } from 'react-router-dom';
import { cellsStyle, headCellsStyle, rowsStyle } from '../../../data/customizer/react-data-table-config';

interface PropsType {
  idBrand?: number;
}

function ManageTask({ idBrand }: PropsType) {
  const handleApiError = useApiError(); // 에러 처리 훅
  const navigate = useNavigate();

  const [dropdownOpen, setOpen] = useState(false);
  const [listData, setListData] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [reqParams, setReqParams] = useState<ReqTaskPage>({
    page: 0,
    size: 5,
    sort: '',
  });

  /**
   * 페이지 데이터 요청.
   * */
  const { isLoading } = useQuery(['getTaskPage', { reqParams }], () => getTaskPage({ ...reqParams, idBrand }), {
    enabled: !!idBrand,
    onSuccess: (data) => {
      setListData(data?._embedded?.tasks || []);
      setTotalRows(data?.page?.totalElements | 0);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 페이지 변경 핸들러
   * */
  const handlePageChange = (page: number) => {
    setReqParams({
      ...reqParams,
      page: page - 1,
    });
  };

  /**
   * 페이지 사이즈 변경
   * */
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParams({
      ...reqParams,
      size: newPerPage,
      page: page - 1,
    });
  };

  /**
   * 아이템 클릭
   * */
  const handleRowClicked = (row: any) => {
    navigate(`/admin/task/update/${row.id}?idBrand=${idBrand}`);
    // TODO 해당 페이지 개발 후, 내부 라우트로 변경 처리해야함.
    // if (window) {
    //   window.open(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/admin/task/update/${row.id}`);
    // }
  };

  /**
   * 드롭다운 토클
   * */
  const dropdownToggle = () => {
    setOpen(!dropdownOpen);
  };

  return (
    <div className="card">
      <div className={'card-header'}>
        <h5>디자이너 작업 목록</h5>
        {idBrand && (
          <Dropdown isOpen={dropdownOpen} toggle={dropdownToggle}>
            <DropdownToggle size={'sm'} color="dark" outline={true}>
              <span>
                작업 <i className="fa fa-caret-down"></i>
              </span>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                // TODO 해당 페이지 개발 후, 내부 라우트로 변경 처리해야함.
                // href={`${process.env.REACT_APP_API_ADMIN_BASE_URL}/admin/task?idBrand=${idBrand}`}
                // target={'_blank'}
                href={`/admin/task/list?idBrand=${idBrand}`}
              >
                {'전체 내역 조회'}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        )}
      </div>
      {!idBrand ? (
        <div className={'card-body'}>
          <p className={`text-center m-5-15 text-danger`}>브랜드를 연결하세요.</p>
        </div>
      ) : (
        <div className={'card-table-body'}>
          <DataTable
            noHeader
            columns={taskColumns}
            data={listData}
            progressPending={isLoading}
            highlightOnHover
            pointerOnHover
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={reqParams.size}
            paginationRowsPerPageOptions={[5, 10, 15]}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            onRowClicked={handleRowClicked}
            dense={true}
            customStyles={{
              rows: rowsStyle,
              headCells: headCellsStyle,
              cells: cellsStyle,
            }}
          />
        </div>
      )}
    </div>
  );
}

export default React.memo(ManageTask);
