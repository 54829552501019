import APIClient from './APIClient';
import { convertToFormData } from '../utils/formUtils';
import { PageInfo } from './types';

export type EditorElementType = 'TEXT' | 'LINE' | 'SHAPE' | 'IMAGE' | 'FRAME' | 'GRAPHIC' | 'BITMAP';
// Element Type
export interface ReqEditorElement {
  type: EditorElementType;
  body?: string;
  thumbnail?: string;
  image?: string;
  color?: string;
  idCategories?: string[];
  idCollections?: string[];
  tagList?: string[];
  active?: boolean;
}
export interface ReqBatchEditorElement extends ReqEditorElement {
  idEditorElement?: number;
}
export interface ReqEditorElementPage extends PageInfo {
  hexColor?: string;
  type?: EditorElementType | '';
  idCategory?: string;
  idCollection?: string;
  active?: boolean | null;
}

// Category Type
export interface ReqEditorElementCategory {
  name?: string;
  active?: boolean;
}
export interface ReqEditorElementCategoryPage extends PageInfo {}

// Collection Type
export interface ReqEditorElementCollection {
  name?: string;
  tags?: string[];
  active?: boolean;
  top?: boolean;
}
export interface ReqEditorElementCollectionPage extends PageInfo {}

/**
 * 요소 페이징
 * */
export const getEditorElementPage = async (params: ReqEditorElementPage) => {
  const response = await APIClient.get('/v2/api/editor/element/page', {
    params,
  });
  return response.data;
};

/**
 * 요소 단일 조회
 * */
export const getEditorElement = async (id: number) => {
  const response = await APIClient.get(`/v2/api/editor/element/${id}`);
  return response.data;
};

/**
 * 요소 생성
 * */
export const createEditorElement = async (editorElement: ReqEditorElement) => {
  const response = await APIClient.post('/v2/api/editor/element', editorElement);
  return response.data;
};

/**
 * 요소 수정
 * */
export const patchEditorElement = async (id: string, editorElement: ReqEditorElement) => {
  const response = await APIClient.patch(`/v2/api/editor/element/${id}`, editorElement);
  return response.data;
};

/**
 * 요소 삭제
 * */
export const deleteEditorElement = async (id: string) => {
  const response = await APIClient.delete(`/v2/api/editor/element/${id}`);
  return response.data;
};

/**
 * 비트맵 & 사진 요소 일괄 업로드
 * */
export const postBatchEditorElement = async (data: { file: File; body: ReqBatchEditorElement }) => {
  const formData = convertToFormData(data.body);
  if (data.file) {
    formData.append('file', data.file);
  }
  const response = await APIClient.post(`/v2/api/editor/element/batch-image`, formData, {
    headers: {
      'content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

/**
 * Enum 타입 조회.
 * */
export const getEditorElementEnum = async () => {
  const response = await APIClient.get('/v2/api/editor/element/enum');
  return response.data;
};

/**
 * Category Page
 */
export const getEditorElementCategoryPage = async (params: ReqEditorElementCategoryPage) => {
  const response = await APIClient.get('/v2/api/editor/element/category/page', {
    params,
  });
  return response.data;
};

/**
 * Category Create
 */
export const postElementCategory = async (data: any) => {
  const response = await APIClient.post('/v2/api/editor/element/category', data);
  return response.data;
};

/**
 * Category Update
 */
export const patchElementCategory = async (id: string, data: any) => {
  const response = await APIClient.patch(`/v2/api/editor/element/category/${id}`, data);
  return response.data;
};

/**
 * Category Get
 */
export const getElementCategory = async (id: string) => {
  const response = await APIClient.get(`/v2/api/editor/element/category/${id}`, {});
  return response.data;
};

/**
 * Category Delete
 */
export const deleteElementCategory = async (id: string) => {
  const response = await APIClient.delete(`/v2/api/editor/element/category/${id}`, {});
  return response.data;
};

/**
 * Collection Page
 */
export const getEditorElementCollectionPage = async (params: ReqEditorElementCollectionPage) => {
  const response = await APIClient.get('/v2/api/editor/element/collection/page', {
    params,
  });
  return response.data;
};

/**
 * Collection Create
 */
export const postElementCollection = async (data: any) => {
  const response = await APIClient.post('/v2/api/editor/element/collection', data);
  return response.data;
};

/**
 * Collection Update
 */
export const patchElementCollection = async (id: string, data: any) => {
  const response = await APIClient.patch(`/v2/api/editor/element/collection/${id}`, data);
  return response.data;
};

/**
 * Collection Get
 */
export const getElementCollection = async (id: string) => {
  const response = await APIClient.get(`/v2/api/editor/element/collection/${id}`, {});
  return response.data;
};

/**
 * Collection Delete
 */
export const deleteElementCollection = async (id: string) => {
  const response = await APIClient.delete(`/v2/api/editor/element/collection/${id}`, {});
  return response.data;
};
