/**
 * RGB => HEX 변환.
 * */
export const rgbToHex = (rgb: string): string => {
  const result = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
  if (!result) return rgb;

  const r = parseInt(result[1], 10).toString(16).padStart(2, '0');
  const g = parseInt(result[2], 10).toString(16).padStart(2, '0');
  const b = parseInt(result[3], 10).toString(16).padStart(2, '0');

  return `#${r}${g}${b}`;
};
