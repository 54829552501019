import { FormGroup, Label } from 'reactstrap';
import moment from 'moment';
import React from 'react';

interface DefaultProps {
  label: string;
  date: Date | string;
}
const TbDateText = ({ label, date }: DefaultProps) => {
  return (
    <FormGroup>
      <Label>{label}</Label>
      <p>{moment(date).format('YYYY-MM-DD HH:mm:ss')}</p>
    </FormGroup>
  );
};
export default React.memo(TbDateText);
