import React, { Fragment, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import {
  createEditorElement,
  deleteEditorElement,
  getEditorElement,
  getEditorElementCategoryPage,
  getEditorElementCollectionPage,
  getEditorElementEnum,
  patchEditorElement,
  ReqEditorElement,
} from '../../api/editorElementAPI';
import TbLoading from '../../components/common/ui/TbLoading';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../components/common/breadcrumb';
import TbModal from '../../components/common/ui/TbModal';
import TbTextarea from '../../components/common/ui/form/TbTextarea';
import { useMutation, useQuery } from 'react-query';
import { enumToSelect } from '../../utils/enumUtils';
import useApiError from '../../hooks/useApiError';
import TbSelect from '../../components/common/ui/form/TbSelect';
import TbRadioBoolean from '../../components/common/ui/form/TbRadioBoolean';
import TbInputTag from '../../components/common/ui/form/TbInputTag';
import TbCheckbox from '../../components/common/ui/form/TbCheckbox';
import { toast } from 'react-toastify';
import TbInputUploadImage from '../../components/common/ui/form/file/TbResourceFileUpload';
import UploadSVGElement from './components/UploadSVGElement';
import TbDateText from '../../components/common/ui/TbDateText';
import TBGenColorPalette from '../../components/common/ui/TBGenColorPalette';
import TbInputColor from '../../components/common/ui/form/TbInputColor';

const EditorElementUpdate = () => {
  const navigate = useNavigate();
  const handleApiError = useApiError(); // 에러 처리 훅
  const { id: idEditorElement } = useParams<{ id: string }>();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const createElement = useMutation(createEditorElement);
  const updateElement = useMutation(({ id, body }: { id: string; body: ReqEditorElement }) =>
    patchEditorElement(id, body),
  );
  const deleteElement = useMutation(deleteEditorElement);
  const [initialFile, setInitialFile] = useState<File[]>([]);

  // Form
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ReqEditorElement>({
    defaultValues: {
      ...(!idEditorElement
        ? // 생성일 경우 초기값.
          {
            active: false, // 여기에 초기값 설정
            idCollections: [],
            idCategories: [],
          }
        : { idCollections: [], idCategories: [] }),
    },
  });

  const watchType = watch('type');
  const watchThumbnail = watch('thumbnail');

  /**
   * Enum 리스트 조회.
   * */
  const { data: enumList } = useQuery(['getEditorElementEnum'], () => getEditorElementEnum(), {
    select: (data) => {
      return enumToSelect(data.typeList);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 업데이트인 경우, 초기 데이터 로드
   * */
  const { data: defaultData } = useQuery(
    ['getEditorElement', idEditorElement],
    () => getEditorElement(Number(idEditorElement)),
    {
      enabled: !!idEditorElement && !!enumList,
      select: (data) => {
        return {
          type: data.type,
          body: data.body,
          thumbnail: data.thumbnail,
          image: data.image,
          idCategories: data.categories?.map((item: any) => item.idElementCategory.toString()),
          idCollections: data.collections?.map((item: any) => item.id.toString()),
          tagList: data.tagList,
          colorGroups: data.colorGroups,
          active: data.active,
          createdDate: data.createdDate,
          updatedDate: data.updatedDate,
        };
      },
      onSuccess: (data) => {
        setValue('type', data.type);
        setValue('body', data.body);
        setValue('thumbnail', data.thumbnail);
        setValue('image', data.image);
        setValue('idCategories', data.idCategories);
        setValue('idCollections', data.idCollections);
        setValue('tagList', data.tagList);
        setValue('color', data.colorGroups?.[0]?.displayColor);
        setValue('active', data.active);
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );
  /**
   * 카테고리 목록 요청.
   * */
  const { data: resCategories } = useQuery(
    ['getEditorElementCategoryPage'],
    () => getEditorElementCategoryPage({ size: 1000 }),
    {
      select: (data) => {
        return data?._embedded?.categories?.map((item: any) => ({
          label: item.name,
          value: item.idElementCategory,
        }));
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 컬렉션 목록 요청.
   * */
  const { data: resCollections } = useQuery(
    ['getEditorElementCollectionPage'],
    () => getEditorElementCollectionPage({ size: 1000 }),
    {
      select: (data) => {
        return data?._embedded?.collections?.map((item: any) => ({
          label: item.name,
          value: item.id,
        }));
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 폼 요청.
   * */
  const onSubmit = async (data: any) => {
    setIsLoading(false);
    const color = data.color === '#000000' ? '' : data.color;
    if (idEditorElement && Number(idEditorElement) > 0) {
      //Update
      try {
        await updateElement.mutateAsync({
          id: idEditorElement,
          body: { ...data, color } as ReqEditorElement,
        });
        toast.success('요소 수정이 완료되었습니다.');
        navigate(-1);
      } catch (error) {
        handleApiError(error);
      }
    } else {
      // Create
      try {
        await createElement.mutateAsync({
          ...data,
          color,
        });
        toast.success('요소가 생성되었습니다.');
        navigate(-1);
      } catch (error) {
        handleApiError(error);
      }
    }

    setIsLoading(false);
  };

  /**
   * 삭제 요청 핸들러
   * */
  const onSubmitDelete = async () => {
    if (idEditorElement) {
      try {
        await deleteElement.mutateAsync(idEditorElement);
        navigate(-1);
        toggleDeleteModal();
        toast.success('요소가 삭제되었습니다.');
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  /**
   * 삭제 모달 토글
   * */
  const toggleDeleteModal = () => {
    setIsDeleteModal(!isDeleteModal);
  };

  return (
    <Fragment>
      <TbModal isOpen={isDeleteModal} onClickConfirm={onSubmitDelete} toggle={toggleDeleteModal}>
        <h5 className="text-center m-0">삭제 하시겠습니까?</h5>
      </TbModal>
      <Container fluid={true}>
        <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
          <Breadcrumb parent="에디터 요소" title={`요소 ${idEditorElement ? '수정' : '생성'}`}>
            <div className="mb-0">
              {idEditorElement ? (
                <Button onClick={toggleDeleteModal} color="danger" className="me-3" type={'button'}>
                  삭제
                </Button>
              ) : (
                ''
              )}

              <Button color="secondary" className="me-3" type="submit">
                {isLoading ? <TbLoading /> : idEditorElement ? '수정' : '생성'}
              </Button>
              <Button onClick={() => navigate(-1)} color="primary">
                목록
              </Button>
            </div>
          </Breadcrumb>

          <Row>
            <Col sm={7}>
              <Card>
                <CardHeader>
                  <h5>콘텐츠</h5>
                </CardHeader>
                <CardBody>
                  <TbTextarea
                    label="디자인 Json Body"
                    id="body"
                    name={'body'}
                    placeholder="{}"
                    rows={10}
                    register={register}
                    errors={errors}
                  />
                  <hr className={'my-3'} />
                  {(watchType === 'IMAGE' || watchType === 'BITMAP') && (
                    <TbInputUploadImage
                      type={'IMAGE'}
                      path={'elements'} // s3의 element 경로에 업로드
                      control={control}
                      label={
                        (watchType === 'IMAGE' ? '사진 원본' : '') || (watchType === 'BITMAP' ? '비트맵 원본' : '')
                      }
                      message={'10MB 이하 JPG, JPEG, PNG 형식만 업로드 가능합니다.'}
                      accept={'.jpeg, .jpg, .png'}
                      name={'image'}
                      initialData={defaultData?.image}
                      errors={errors}
                    />
                  )}

                  {/* SVG 요소 업로드 시(검증 로직이 포함되어 있음)*/}
                  {watchType === 'GRAPHIC' && (
                    <UploadSVGElement
                      path={'elements'}
                      label={'SVG 요소 업로드'}
                      message={'5MB 이하 SVG 형식만 업로드 가능합니다.'}
                      maxSize={5}
                      initialData={defaultData?.image}
                      onGenerateJson={(json: any) => {
                        setValue('body', JSON.stringify(json));
                      }}
                      onGenerateThumbnail={(file: File) => {
                        // 기존 썸네일이 없는 경우만 처리
                        if (!watchThumbnail) {
                          setInitialFile([file]);
                        }
                      }}
                      control={control}
                      errors={errors}
                    />
                  )}

                  {/* 이미지, 비트맵 타입의 썸네일은 자동 설정 됩니다.*/}
                  {watchType !== 'IMAGE' && watchType !== 'BITMAP' && (
                    <TbInputUploadImage
                      type={'IMAGE'}
                      path={'elements'} // s3의 element 경로에 업로드
                      control={control}
                      label={'썸네일'}
                      message={'1MB 이하 JPG, JPEG, PNG형식만 업로드 가능합니다.'}
                      accept={'.jpeg, .jpg, .png'}
                      name={'thumbnail'}
                      maxSize={1}
                      initialData={defaultData?.thumbnail}
                      initialFile={initialFile}
                      errors={errors}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col sm={5}>
              <Card>
                <CardHeader>
                  <h5>설정</h5>
                </CardHeader>
                <CardBody>
                  <TbSelect
                    label="요소 유형"
                    name="type"
                    readonly={!!idEditorElement}
                    options={enumList ? enumList : []}
                    horizontal={true}
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <FormGroup>
                    <Label>카테고리</Label>
                    <div>
                      {resCategories?.map((item: any) => (
                        <TbCheckbox
                          key={item.value}
                          label={item.label}
                          name={'idCategories'}
                          value={item.value}
                          type={'checkbox'}
                          horizontal={true}
                          validation={{ required: true }}
                          register={register}
                          errors={errors}
                        />
                      ))}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label>컬렉션</Label>
                    <div>
                      {resCollections?.map((item: any) => (
                        <TbCheckbox
                          key={item.value}
                          label={item.label}
                          name={'idCollections'}
                          value={item.value}
                          type={'checkbox'}
                          horizontal={true}
                          register={register}
                          errors={errors}
                        />
                      ))}
                    </div>
                  </FormGroup>
                  <TbInputTag control={control} label="검색 태그" name={'tagList'} errors={errors} />
                  <TbInputColor
                    label={'검색 컬러 지정'}
                    name={'color'}
                    message={"'#000000'은 적용되지 않습니다."}
                    register={register}
                    errors={errors}
                    validate={(value: any) => {
                      return !value || value === '#000000' ? '검색 컬러를 지정하세요.' : true;
                    }}
                  />
                  {/** 컬러 추출 */}
                  {watchThumbnail && (
                    <TBGenColorPalette
                      url={watchThumbnail}
                      onSelect={(hex) => {
                        setValue('color', hex);
                      }}
                    />
                  )}
                  <hr className={'my-4'} />
                  <TbRadioBoolean
                    control={control}
                    message={'비활성일 경우 콘텐츠가 노출되지 않습니다.'}
                    label={'게시 상태'}
                    name="active"
                    register={register}
                  />
                  <TbDateText label={'생성일'} date={defaultData?.createdDate} />
                  <TbDateText label={'수정일'} date={defaultData?.updatedDate} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </Fragment>
  );
};
export default React.memo(EditorElementUpdate);
